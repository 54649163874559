import { class_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Msg$2, view, update, Model$2__IsEditing, initEdit, initNew } from "../../Omnicv.Client.Common/Components/AddEditRecordFlexFormBase.js";
import { Msg, tryUpdateLocationFromSuggestions } from "../../Omnicv.Client.Common/Components/EventInfoManager.js";
import { FlexFormFields$1__AddDurationSecs_ZAAC24, FlexFormFields$1__AddGroup_6DFDD678, FlexFormFields$1__AddRating_Z716E223C, FlexFormFields$1__AddRadiosInline_Z70244BEA, FlexFormBase$1__set_SubmitCaption_Z721C83C5, FlexFormTuple$2__get_FieldsSnd, FlexFormFields$1__AddPositiveFloat_1D6F52FB, FlexFormFields$1__AddTextAreaOpt_Z44636648, addEventInfoToFlexFormEim, FlexFormTuple$2_$ctor_5AE4FFF0, getSuggestions } from "../../Omnicv.Client.Common/Components/FlexForm.js";
import { defaultArg } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { Route, Router_modifyLocation, Router_newUrl } from "../../Omnicv.Client.Common/Router.js";
import { Session__GetCurrentProfileId } from "../../Omnicv.Client.Common/SharedView.js";
import { PagingOptionsModule_defaultPagingOptions } from "../../Omnicv.Shared.Common/CoreEventInfo.js";
import { temperatureService, bodilyFunctionsService, bloodPressureService, weightService } from "./PhysicalCommon.js";
import { validateTemperature, TemperatureRecord_Default, duringSleepOptions, bodilyFunctions, BodilyFunctionsRecord_Default, validateSystolic, validateDiastolic, BloodPressureRecord_Default, validateWeight, validateNotes, WeightRecord_Default } from "../../Omnicv.Shared/PhysicalAPI.js";

function noValidation(_arg) {
    return undefined;
}

export class Physical_PhysicalView$1 {
    constructor(service, getDefault, addTitle, editTitle, viewAll, buildFormMain) {
        this.service = service;
        this.getDefault = getDefault;
        this.addTitle = addTitle;
        this.editTitle = editTitle;
        this.viewAll = viewAll;
        this.buildFormMain = buildFormMain;
    }
}

export function Physical_PhysicalView$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Pages.Physical.AddEditPhysicalView.Component.Physical.PhysicalView`1", [gen0], Physical_PhysicalView$1);
}

export function Physical_PhysicalView$1_$ctor_Z7E8B6EC8(service, getDefault, addTitle, editTitle, viewAll, buildFormMain) {
    return new Physical_PhysicalView$1(service, getDefault, addTitle, editTitle, viewAll, buildFormMain);
}

export function Physical_PhysicalView$1__InitNew_5D846BF8(this$, session) {
    return initNew(this$.getDefault(), () => Physical_PhysicalView$1__fetchAddHelperData(this$, session, undefined));
}

export function Physical_PhysicalView$1__InitEdit(this$, session, id) {
    return initEdit(id, this$.getDefault(), (id_1) => Physical_PhysicalView$1__fetchData(this$, session, id_1));
}

export function Physical_PhysicalView$1__Update(this$, session, msg, model) {
    if (!Model$2__IsEditing(model)) {
        tryUpdateLocationFromSuggestions(() => getSuggestions(model.EIMModel.Position, defaultArg(model.EditHelperData, empty())), model.EIMModel);
    }
    return update(session, (eventInfo, record) => {
        if (Model$2__IsEditing(model)) {
            return this$.service.Edit(eventInfo, record);
        }
        else {
            return this$.service.Add(eventInfo, record);
        }
    }, () => Router_newUrl(this$.viewAll), msg, model);
}

export function Physical_PhysicalView$1__View(this$, model, dispatch) {
    const editing = Model$2__IsEditing(model);
    return view((model_1, dispatch_1) => Physical_PhysicalView$1__buildForm(this$, editing, model_1, dispatch_1), editing ? this$.editTitle : this$.addTitle, model, dispatch);
}

export function Physical_PhysicalView$1__fetchData(this$, session, id) {
    return this$.service.GetRecord(Session__GetCurrentProfileId(session), id);
}

export function Physical_PhysicalView$1__fetchAddHelperData(this$, session, _arg) {
    return this$.service.GetRecords(Session__GetCurrentProfileId(session), PagingOptionsModule_defaultPagingOptions);
}

export function Physical_PhysicalView$1__buildForm(this$, editing, model, dispatch) {
    const form = FlexFormTuple$2_$ctor_5AE4FFF0((arg) => {
        dispatch(new Msg$2(0, [arg]));
    }, (arg_1) => {
        dispatch(new Msg$2(1, [arg_1]));
    }, (_arg) => {
        dispatch(new Msg$2(5, []));
    }, () => {
        Router_modifyLocation(this$.viewAll);
    });
    addEventInfoToFlexFormEim(form, model.EIMModel, (arg_3) => {
        dispatch(new Msg$2(7, [new Msg(6, [arg_3])]));
    }, getSuggestions(model.EIMModel.Position, defaultArg(model.EditHelperData, empty())));
    return this$.buildFormMain(form, editing, model);
}

export const Physical_weightEditing = Physical_PhysicalView$1_$ctor_Z7E8B6EC8(weightService, WeightRecord_Default, "Add new weight", "Edit weight", new Route(18, []), (form, editing, model) => {
    FlexFormFields$1__AddTextAreaOpt_Z44636648(FlexFormFields$1__AddPositiveFloat_1D6F52FB(FlexFormTuple$2__get_FieldsSnd(form), "Weight", validateWeight), "Notes", validateNotes);
    if (editing) {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Update weight");
    }
    else {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Add weight");
    }
    return form;
});

export const Physical_bloodPressureEditing = Physical_PhysicalView$1_$ctor_Z7E8B6EC8(bloodPressureService, BloodPressureRecord_Default, "Add blood pressure", "Edit blood pressure", new Route(18, []), (form, editing, model) => {
    FlexFormFields$1__AddTextAreaOpt_Z44636648(FlexFormFields$1__AddPositiveFloat_1D6F52FB(FlexFormFields$1__AddPositiveFloat_1D6F52FB(FlexFormTuple$2__get_FieldsSnd(form), "Systolic", validateSystolic), "Diastolic", validateDiastolic), "Notes", validateNotes);
    if (editing) {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Update blood pressure");
    }
    else {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Add blood pressure");
    }
    return form;
});

export const Physical_bodilyFunctionsEditing = Physical_PhysicalView$1_$ctor_Z7E8B6EC8(bodilyFunctionsService, BodilyFunctionsRecord_Default, "Add elimination", "Edit elimination", new Route(19, []), (form, editing, model) => {
    FlexFormFields$1__AddRadiosInline_Z70244BEA(FlexFormTuple$2__get_FieldsSnd(form), "FunctionType", bodilyFunctions, noValidation);
    FlexFormFields$1__AddRadiosInline_Z70244BEA(FlexFormFields$1__AddRating_Z716E223C(FlexFormFields$1__AddGroup_6DFDD678(FlexFormTuple$2__get_FieldsSnd(form)), "Rating", noValidation), "DuringSleep", duringSleepOptions, noValidation);
    FlexFormFields$1__AddTextAreaOpt_Z44636648(FlexFormFields$1__AddDurationSecs_ZAAC24(FlexFormTuple$2__get_FieldsSnd(form), "DurationSec", noValidation), "Notes", validateNotes);
    if (editing) {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Update elimination");
    }
    else {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Add elimination");
    }
    return form;
});

export const Physical_temperatureEditing = Physical_PhysicalView$1_$ctor_Z7E8B6EC8(temperatureService, TemperatureRecord_Default, "Add new temperature", "Edit temperature", new Route(18, []), (form, editing, model) => {
    FlexFormFields$1__AddTextAreaOpt_Z44636648(FlexFormFields$1__AddPositiveFloat_1D6F52FB(FlexFormTuple$2__get_FieldsSnd(form), "Temperature", validateTemperature), "Notes", validateNotes);
    if (editing) {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Update temperature");
    }
    else {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Add temperature");
    }
    return form;
});

