import { createElement } from "react";
import { equals, createObj } from "../fable-library-js.4.19.3/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../Feliz.Bulma.3.0.0/ElementBuilders.fs.js";
import { map as map_1, cons, empty, ofArray, singleton } from "../fable-library-js.4.19.3/List.js";
import { defaultArgWith, value as value_29, map, defaultArg } from "../fable-library-js.4.19.3/Option.js";
import { View_custom, View_CustomConfig$2, View_InputType, View_State, View_errorToString } from "../Fable.Form.Simple.4.1.0/Form.fs.js";
import { reactApi } from "../Feliz.2.8.0/Interop.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable-library-js.4.19.3/Seq.js";
import { join } from "../fable-library-js.4.19.3/String.js";
import { head, initialize } from "../fable-library-js.4.19.3/Array.js";
import { FilledField$1 } from "../Fable.Form.2.0.0/Base.fs.js";
import { defaultOf } from "../fable-library-js.4.19.3/Util.js";

export function fieldLabel(label) {
    return createElement("label", createObj(Helpers_combineClasses("label", singleton(["children", label]))));
}

export function errorMessage(message) {
    return createElement("p", createObj(Helpers_combineClasses("help", ofArray([["className", "is-danger"], ["children", message]]))));
}

export function errorMessageAsHtml(showError, error) {
    let matchResult, externalError;
    if (error != null) {
        if (error.tag === 2) {
            matchResult = 0;
            externalError = error.fields[0];
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return errorMessage(externalError);
        default:
            if (showError) {
                return defaultArg(map(errorMessage, map(View_errorToString, error)), createElement("p", createObj(Helpers_combineClasses("help", empty()))));
            }
            else {
                return createElement("p", createObj(Helpers_combineClasses("help", empty())));
            }
    }
}

export function wrapInFieldContainer(children) {
    return createElement("div", createObj(Helpers_combineClasses("field", singleton(["children", reactApi.Children.toArray(Array.from(children))]))));
}

export function withLabelAndError(label, showError, error, fieldAsHtml) {
    return wrapInFieldContainer(ofArray([fieldLabel(label), createElement("div", {
        className: "control",
        children: reactApi.Children.toArray([fieldAsHtml]),
    }), errorMessageAsHtml(showError, error)]));
}

export function inputField(typ, _arg) {
    const showError = _arg.ShowError;
    const error = _arg.Error;
    const dispatch = _arg.Dispatch;
    const attributes = _arg.Attributes;
    return withLabelAndError(attributes.Label, showError, error, ((typ.tag === 1) ? ((props_3) => createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", props_3))))) : ((typ.tag === 2) ? ((props_6) => createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", props_6))))) : ((typ.tag === 3) ? ((props_9) => createElement("input", createObj(cons(["type", "color"], Helpers_combineClasses("input", props_9))))) : ((typ.tag === 4) ? ((props_12) => createElement("input", createObj(cons(["type", "date"], Helpers_combineClasses("input", props_12))))) : ((typ.tag === 5) ? ((props_15) => createElement("input", createObj(cons(["type", "datetime-local"], Helpers_combineClasses("input", props_15))))) : ((typ.tag === 6) ? ((props_18) => createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_18))))) : ((typ.tag === 7) ? ((props_21) => createElement("input", createObj(cons(["type", "search"], Helpers_combineClasses("input", props_21))))) : ((typ.tag === 8) ? ((props_24) => createElement("input", createObj(cons(["type", "tel"], Helpers_combineClasses("input", props_24))))) : ((typ.tag === 9) ? ((props_27) => createElement("input", createObj(cons(["type", "time"], Helpers_combineClasses("input", props_27))))) : ((props) => createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props))))))))))))))(toList(delay(() => append(singleton_1(["onChange", (ev) => {
        dispatch(_arg.OnChange(ev.target.value));
    }]), delay(() => {
        let matchValue, onBlur_1;
        return append((matchValue = _arg.OnBlur, (matchValue == null) ? (empty_1()) : ((onBlur_1 = value_29(matchValue), singleton_1(["onBlur", (_arg_1) => {
            dispatch(onBlur_1);
        }])))), delay(() => append(singleton_1(["disabled", _arg.Disabled]), delay(() => append(singleton_1(["value", _arg.Value]), delay(() => append(singleton_1(["placeholder", attributes.Placeholder]), delay(() => append((showError && (error != null)) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => attributes.HtmlAttributes))))))))));
    }))))));
}

export function textareaField(_arg) {
    const showError = _arg.ShowError;
    const error = _arg.Error;
    const dispatch = _arg.Dispatch;
    const attributes = _arg.Attributes;
    return withLabelAndError(attributes.Label, showError, error, createElement("textarea", createObj(Helpers_combineClasses("textarea", toList(delay(() => append(singleton_1(["onChange", (ev) => {
        dispatch(_arg.OnChange(ev.target.value));
    }]), delay(() => {
        let matchValue, onBlur_1;
        return append((matchValue = _arg.OnBlur, (matchValue == null) ? (empty_1()) : ((onBlur_1 = value_29(matchValue), singleton_1(["onBlur", (_arg_1) => {
            dispatch(onBlur_1);
        }])))), delay(() => append(singleton_1(["disabled", _arg.Disabled]), delay(() => append(singleton_1(["value", _arg.Value]), delay(() => append(singleton_1(["placeholder", attributes.Placeholder]), delay(() => append((showError && (error != null)) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => attributes.HtmlAttributes))))))))));
    }))))))));
}

export function checkboxField(_arg) {
    let elms, elems;
    const dispatch = _arg.Dispatch;
    return wrapInFieldContainer(singleton((elms = singleton(createElement("label", createObj(Helpers_combineClasses("checkbox", singleton((elems = [createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("checkbox", toList(delay(() => append(singleton_1(["onChange", (ev) => {
        dispatch(_arg.OnChange(ev.target.checked));
    }]), delay(() => {
        let matchValue, onBlur_1;
        return append((matchValue = _arg.OnBlur, (matchValue == null) ? (empty_1()) : ((onBlur_1 = value_29(matchValue), singleton_1(["onBlur", (_arg_1) => {
            dispatch(onBlur_1);
        }])))), delay(() => append(singleton_1(["disabled", _arg.Disabled]), delay(() => singleton_1(["checked", _arg.Value])))));
    })))))))), _arg.Attributes.Text], ["children", reactApi.Children.toArray(Array.from(elems))])))))), createElement("div", {
        className: "control",
        children: reactApi.Children.toArray(Array.from(elms)),
    }))));
}

export function radioField(_arg) {
    let elems_1;
    const dispatch = _arg.Dispatch;
    const attributes = _arg.Attributes;
    return withLabelAndError(attributes.Label, _arg.ShowError, _arg.Error, createElement("div", createObj(Helpers_combineClasses("control", singleton((elems_1 = map_1((tupledArg) => {
        const key = tupledArg[0];
        const elms = ofArray([createElement("input", createObj(cons(["type", "radio"], Helpers_combineClasses("radio", toList(delay(() => append(singleton_1(["name", attributes.Label]), delay(() => append(singleton_1(["checked", key === _arg.Value]), delay(() => append(singleton_1(["disabled", _arg.Disabled]), delay(() => append(singleton_1(["onChange", (ev) => {
            const _arg_1 = ev.target.checked;
            dispatch(_arg.OnChange(key));
        }]), delay(() => {
            const matchValue = _arg.OnBlur;
            if (matchValue == null) {
                return empty_1();
            }
            else {
                const onBlur_1 = value_29(matchValue);
                return singleton_1(["onBlur", (_arg_2) => {
                    dispatch(onBlur_1);
                }]);
            }
        })))))))))))))), tupledArg[1]]);
        return createElement("label", {
            className: "radio",
            children: reactApi.Children.toArray(Array.from(elms)),
        });
    }, attributes.Options), ["children", reactApi.Children.toArray(Array.from(elems_1))]))))));
}

export function selectField(_arg) {
    let patternInput, elems_1;
    const dispatch = _arg.Dispatch;
    const attributes = _arg.Attributes;
    const placeholderOption = createElement("option", {
        disabled: true,
        value: "",
        children: ("-- " + attributes.Placeholder) + " --",
    });
    return withLabelAndError(attributes.Label, _arg.ShowError, _arg.Error, (patternInput = Helpers_extractClasses(toList(delay(() => append(singleton_1(["disabled", _arg.Disabled]), delay(() => append(singleton_1(["onChange", (ev) => {
        dispatch(_arg.OnChange(ev.target.value));
    }]), delay(() => {
        let matchValue, onBlur_1;
        return append((matchValue = _arg.OnBlur, (matchValue == null) ? (empty_1()) : ((onBlur_1 = value_29(matchValue), singleton_1(["onBlur", (_arg_1) => {
            dispatch(onBlur_1);
        }])))), delay(() => append(singleton_1(["value", _arg.Value]), delay(() => {
            let elems;
            return singleton_1((elems = toList(delay(() => append(singleton_1(placeholderOption), delay(() => map_1((tupledArg) => createElement("option", {
                value: tupledArg[0],
                children: tupledArg[1],
            }), attributes.Options))))), ["children", reactApi.Children.toArray(Array.from(elems))]));
        }))));
    }))))))), createElement("div", createObj(ofArray([["className", join(" ", cons("select", patternInput[0]))], (elems_1 = [createElement("select", createObj(patternInput[1]))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))));
}

export function fileField(_arg) {
    const value = _arg.Value;
    const attributes = _arg.Attributes;
    return withLabelAndError(attributes.Label, _arg.ShowError, _arg.Error, createElement("div", createObj(Helpers_combineClasses("file", toList(delay(() => append(!(value.length === 0) ? singleton_1(["className", "has-name"]) : empty_1(), delay(() => {
        let elems_3, elms_2;
        return singleton_1((elems_3 = [(elms_2 = toList(delay(() => append(singleton_1(createElement("input", createObj(cons(["type", "file"], Helpers_combineClasses("file-input", toList(delay(() => append(singleton_1(["onInput", (x) => {
            const files = x.currentTarget.files;
            _arg.Dispatch(_arg.OnChange(initialize(files.length, (i) => (files[i]))));
        }]), delay(() => append(singleton_1(["multiple", attributes.Multiple]), delay(() => {
            let matchValue;
            return append((matchValue = attributes.Accept, (matchValue.tag === 1) ? singleton_1(["accept", join(",", matchValue.fields[0])]) : (empty_1())), delay(() => singleton_1(["disabled", _arg.Disabled])));
        }))))))))))), delay(() => {
            let elms_1;
            return append(singleton_1((elms_1 = toList(delay(() => {
                let matchValue_1, elms, content;
                return append((matchValue_1 = attributes.FileIconClassName, (matchValue_1.tag === 1) ? singleton_1((elms = singleton(createElement("i", {
                    className: matchValue_1.fields[0],
                })), createElement("span", {
                    className: "file-icon",
                    children: reactApi.Children.toArray(Array.from(elms)),
                }))) : singleton_1(createElement("span", createObj(Helpers_combineClasses("file-icon", singleton((content = "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"lucide lucide-upload\">\n    <path d=\"M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4\"/>\n    <polyline points=\"17 8 12 3 7 8\"/>\n    <line x1=\"12\" x2=\"12\" y1=\"3\" y2=\"15\"/>\n</svg>\n<!--\n    This icon has been taken from Lucide icons project\n\n    See: https://lucide.dev/license\n-->", ["dangerouslySetInnerHTML", {
                    __html: content,
                }]))))))), delay(() => singleton_1(createElement("span", createObj(Helpers_combineClasses("file-label", singleton(["children", attributes.InputLabel])))))));
            })), createElement("span", {
                className: "file-cta",
                children: reactApi.Children.toArray(Array.from(elms_1)),
            }))), delay(() => (!(value.length === 0) ? singleton_1(createElement("span", createObj(Helpers_combineClasses("file-name", singleton(["children", head(value).name]))))) : empty_1())));
        })))), createElement("label", {
            className: "file-label",
            children: reactApi.Children.toArray(Array.from(elms_2)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_3))]));
    }))))))));
}

export function group(fields) {
    let elems_1;
    const elms = singleton(createElement("div", createObj(Helpers_combineClasses("columns", singleton((elems_1 = map_1((i) => createElement("div", {
        className: "column",
        children: reactApi.Children.toArray([i]),
    }), fields), ["children", reactApi.Children.toArray(Array.from(elems_1))]))))));
    return createElement("div", {
        className: "field",
        children: reactApi.Children.toArray(Array.from(elms)),
    });
}

export function section(title, fields) {
    let elems;
    return createElement("fieldset", createObj(ofArray([["className", "fieldset"], (elems = toList(delay(() => append(singleton_1(createElement("legend", {
        children: title,
    })), delay(() => fields)))), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function ignoreChildError(parentError, field) {
    if (parentError == null) {
        return new FilledField$1(field.State, undefined, field.IsDisabled);
    }
    else {
        return field;
    }
}

export function formList(_arg) {
    let elems_1, elems, elms;
    const add = _arg.Add;
    let addButton;
    let matchResult;
    if (_arg.Disabled) {
        matchResult = 1;
    }
    else if (add != null) {
        matchResult = 0;
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0: {
            const add_1 = add;
            addButton = createElement("a", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg_1) => {
                _arg.Dispatch(add_1.Action());
            }], (elems_1 = [createElement("span", createObj(Helpers_combineClasses("icon", ofArray([["className", "is-small"], (elems = [createElement("i", {
                className: "fas fa-plus",
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), createElement("span", {
                children: [add_1.Label],
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
            break;
        }
        default:
            addButton = defaultOf();
    }
    const elms_1 = singleton((elms = toList(delay(() => append(singleton_1(fieldLabel(_arg.Label)), delay(() => append(_arg.Forms, delay(() => singleton_1(addButton))))))), createElement("div", {
        className: "control",
        children: reactApi.Children.toArray(Array.from(elms)),
    })));
    return createElement("div", {
        className: "field",
        children: reactApi.Children.toArray(Array.from(elms_1)),
    });
}

export function formListItem(_arg) {
    let elems_1, elems_4;
    const delete$ = _arg.Delete;
    let removeButton;
    let matchResult;
    if (_arg.Disabled) {
        matchResult = 1;
    }
    else if (delete$ != null) {
        matchResult = 0;
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0: {
            const delete$_1 = delete$;
            removeButton = createElement("a", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg_1) => {
                _arg.Dispatch(delete$_1.Action());
            }], (elems_1 = toList(delay(() => {
                let elems;
                return append(singleton_1(createElement("span", createObj(Helpers_combineClasses("icon", ofArray([["className", "is-small"], (elems = [createElement("i", {
                    className: "fas fa-times",
                })], ["children", reactApi.Children.toArray(Array.from(elems))])]))))), delay(() => ((delete$_1.Label !== "") ? singleton_1(createElement("span", {
                    children: [delete$_1.Label],
                })) : empty_1())));
            })), ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
            break;
        }
        default:
            removeButton = defaultOf();
    }
    return createElement("div", createObj(ofArray([["className", "form-list"], (elems_4 = toList(delay(() => append(_arg.Fields, delay(() => {
        let elems_3;
        return singleton_1(createElement("div", createObj(Helpers_combineClasses("field", ofArray([["className", "is-grouped"], ["className", "is-grouped-right"], (elems_3 = [createElement("div", {
            className: "control",
            children: reactApi.Children.toArray([removeButton]),
        })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))));
    })))), ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function form(_arg) {
    let elems_3;
    const state = _arg.State;
    const dispatch = _arg.Dispatch;
    return createElement("form", createObj(ofArray([["onSubmit", (ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        defaultArgWith(map(dispatch, _arg.OnSubmit), () => {
        });
    }], (elems_3 = toList(delay(() => append(_arg.Fields, delay(() => {
        let matchValue, elms;
        return append((matchValue = state, (matchValue.tag === 3) ? singleton_1((elms = singleton(createElement("div", createObj(Helpers_combineClasses("control", ofArray([["className", "has-text-centered"], ["className", "has-text-success"], ["className", "has-text-weight-bold"], ["children", matchValue.fields[0]]]))))), createElement("div", {
            className: "field",
            children: reactApi.Children.toArray(Array.from(elms)),
        }))) : ((matchValue.tag === 1) ? singleton_1(defaultOf()) : ((matchValue.tag === 0) ? singleton_1(defaultOf()) : singleton_1(errorMessage(matchValue.fields[0]))))), delay(() => {
            let elems_2, elms_1;
            const matchValue_1 = _arg.Action;
            return (matchValue_1.tag === 1) ? singleton_1(matchValue_1.fields[0](state, dispatch)) : singleton_1(createElement("div", createObj(Helpers_combineClasses("field", ofArray([["className", "is-grouped"], ["className", "is-grouped-right"], (elems_2 = [(elms_1 = singleton(createElement("button", createObj(Helpers_combineClasses("button", toList(delay(() => append(singleton_1(["type", "submit"]), delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(singleton_1(["children", matchValue_1.fields[0]]), delay(() => (equals(state, new View_State(1, [])) ? singleton_1(["className", "is-loading"]) : empty_1()))))))))))))), createElement("div", {
                className: "control",
                children: reactApi.Children.toArray(Array.from(elms_1)),
            }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))));
        }));
    })))), ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function htmlViewConfig() {
    return new View_CustomConfig$2(form, (config_1) => inputField(new View_InputType(0, []), config_1), (config_2) => inputField(new View_InputType(1, []), config_2), (config_3) => inputField(new View_InputType(2, []), config_3), (config_5) => inputField(new View_InputType(3, []), config_5), (config_6) => inputField(new View_InputType(4, []), config_6), (config_7) => inputField(new View_InputType(5, []), config_7), (config_8) => inputField(new View_InputType(6, []), config_8), (config_9) => inputField(new View_InputType(7, []), config_9), (config_10) => inputField(new View_InputType(8, []), config_10), (config_11) => inputField(new View_InputType(9, []), config_11), textareaField, checkboxField, radioField, selectField, fileField, group, section, formList, formListItem);
}

export function asHtml(config) {
    return (form_1) => ((model) => View_custom(htmlViewConfig(), config, form_1, model));
}

