import { Constraints_minMaxConstraint, Constraints_collectConstraintsErrors, Constraints_applyConstraintsGetErrors, Constraints_minMaxOptLengthConstraint } from "../Omnicv.Shared.Common/Validation.js";
import { head, empty, ofArray, singleton } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { tryFind } from "../fable_modules/fable-library-js.4.19.3/Map.js";
import { Union, Record } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { list_type, tuple_type, lambda_type, union_type, class_type, bool_type, uint8_type, enum_type, int32_type, record_type, option_type, string_type, float64_type, int64_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { compare } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { PagingOptions_$reflection, EventInfo_$reflection } from "../Omnicv.Shared.Common/CoreEventInfo.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { ChangelogEntry, Version, ProductInformation, Changelog, defaultWeb, defaultContacts, defaultAuthors } from "../Omnicv.Shared.Common/ProductDetails.js";
import { parse } from "../fable_modules/fable-library-js.4.19.3/Date.js";

const notesConstraints = singleton((name) => ((value) => Constraints_minMaxOptLengthConstraint(5, 1000, name, value)));

export const notesName = "Notes";

function getNotesErrors(notes) {
    return Constraints_applyConstraintsGetErrors(notesConstraints, notesName, notes);
}

export function validateNotes(notes) {
    return tryFind(notesName, Constraints_collectConstraintsErrors(singleton(getNotesErrors(notes))));
}

const weightConstraints = singleton((name) => ((value) => Constraints_minMaxConstraint(1, 500, name, value)));

export const weightName = "Weight";

function getWeightErrors(weight) {
    return Constraints_applyConstraintsGetErrors(weightConstraints, weightName, weight);
}

export function validateWeight(weight) {
    return tryFind(weightName, Constraints_collectConstraintsErrors(singleton(getWeightErrors(weight))));
}

const temperatureConstraints = singleton((name) => ((value) => Constraints_minMaxConstraint(1, 50, name, value)));

export const temperatureName = "Temperature";

function getTemperatureErrors(temperature) {
    return Constraints_applyConstraintsGetErrors(temperatureConstraints, temperatureName, temperature);
}

export function validateTemperature(temperature) {
    return tryFind(temperatureName, Constraints_collectConstraintsErrors(singleton(getTemperatureErrors(temperature))));
}

const pressureConstraints = singleton((name) => ((value) => Constraints_minMaxConstraint(1, 500, name, value)));

export const systolicName = "Systolic";

export const diastolicName = "Diastolic";

function getPressureErrors(name, pressure) {
    return Constraints_applyConstraintsGetErrors(pressureConstraints, name, pressure);
}

export function validateSystolic(pressure) {
    return tryFind(systolicName, Constraints_collectConstraintsErrors(singleton(getPressureErrors(systolicName, pressure))));
}

export function validateDiastolic(pressure) {
    return tryFind(diastolicName, Constraints_collectConstraintsErrors(singleton(getPressureErrors(diastolicName, pressure))));
}

function getRatingErrors(rating) {
    return Constraints_applyConstraintsGetErrors(singleton((name_1) => ((value) => Constraints_minMaxConstraint(1, 10, name_1, value))), "Rating", rating);
}

export class WeightRecord extends Record {
    constructor(Id, Weight, Notes) {
        super();
        this.Id = Id;
        this.Weight = Weight;
        this.Notes = Notes;
    }
}

export function WeightRecord_$reflection() {
    return record_type("Omnicv.Shared.PhysicalAPI.WeightRecord", [], WeightRecord, () => [["Id", int64_type], ["Weight", float64_type], ["Notes", option_type(string_type)]]);
}

function WeightRecord_Validator_Z880D0EE(record) {
    return Constraints_collectConstraintsErrors(ofArray([getWeightErrors(record.Weight), getNotesErrors(record.Notes)]));
}

export function WeightRecord_Default() {
    return new WeightRecord(0n, 0, undefined);
}

export function WeightRecord__Validate(this$) {
    return WeightRecord_Validator_Z880D0EE(this$);
}

export function WeightRecord__ValidateField_Z721C83C5(this$, name) {
    return tryFind(name, WeightRecord__Validate(this$));
}

export class TemperatureRecord extends Record {
    constructor(Id, Temperature, Notes) {
        super();
        this.Id = Id;
        this.Temperature = Temperature;
        this.Notes = Notes;
    }
}

export function TemperatureRecord_$reflection() {
    return record_type("Omnicv.Shared.PhysicalAPI.TemperatureRecord", [], TemperatureRecord, () => [["Id", int64_type], ["Temperature", float64_type], ["Notes", option_type(string_type)]]);
}

function TemperatureRecord_Validator_Z70227002(record) {
    return Constraints_collectConstraintsErrors(ofArray([getTemperatureErrors(record.Temperature), getNotesErrors(record.Notes)]));
}

export function TemperatureRecord_Default() {
    return new TemperatureRecord(0n, 0, undefined);
}

export function TemperatureRecord__Validate(this$) {
    return TemperatureRecord_Validator_Z70227002(this$);
}

export function TemperatureRecord__ValidateField_Z721C83C5(this$, name) {
    return tryFind(name, TemperatureRecord__Validate(this$));
}

export class BloodPressureRecord extends Record {
    constructor(Id, Systolic, Diastolic, Notes) {
        super();
        this.Id = Id;
        this.Systolic = Systolic;
        this.Diastolic = Diastolic;
        this.Notes = Notes;
    }
}

export function BloodPressureRecord_$reflection() {
    return record_type("Omnicv.Shared.PhysicalAPI.BloodPressureRecord", [], BloodPressureRecord, () => [["Id", int64_type], ["Systolic", float64_type], ["Diastolic", float64_type], ["Notes", option_type(string_type)]]);
}

function BloodPressureRecord_Validator_21088E9D(record) {
    const rangeNotInverted = (record.Systolic <= record.Diastolic) ? [systolicName, `Systolic [${record.Systolic} must be greater than diastolic [${record.Diastolic}`] : undefined;
    return Constraints_collectConstraintsErrors(ofArray([getPressureErrors(systolicName, record.Systolic), getPressureErrors(diastolicName, record.Diastolic), getNotesErrors(record.Notes), rangeNotInverted]));
}

export function BloodPressureRecord_Default() {
    return new BloodPressureRecord(0n, 0, 0, undefined);
}

export function BloodPressureRecord__Validate(this$) {
    return BloodPressureRecord_Validator_21088E9D(this$);
}

export function BloodPressureRecord__ValidateField_Z721C83C5(this$, name) {
    return tryFind(name, BloodPressureRecord__Validate(this$));
}

export const bodilyFunctions = ofArray([[1, "Number 1"], [2, "Number 2"]]);

export const duringSleepOptions = ofArray([[undefined, "Auto"], [true, "During sleep"], [false, "When awake"]]);

export const bodilyFunctionsEmojis = ofArray([[1, "🌊"], [2, "💩"]]);

export class BodilyFunctionsRecord extends Record {
    constructor(Id, FunctionType, DurationSec, Rating, DuringSleep, Notes) {
        super();
        this.Id = Id;
        this.FunctionType = (FunctionType | 0);
        this.DurationSec = (DurationSec | 0);
        this.Rating = Rating;
        this.DuringSleep = DuringSleep;
        this.Notes = Notes;
    }
}

export function BodilyFunctionsRecord_$reflection() {
    return record_type("Omnicv.Shared.PhysicalAPI.BodilyFunctionsRecord", [], BodilyFunctionsRecord, () => [["Id", int64_type], ["FunctionType", enum_type("Omnicv.Shared.PhysicalAPI.BodilyFunctions", int32_type, [["Urination", 1], ["Defecation", 2]])], ["DurationSec", int32_type], ["Rating", uint8_type], ["DuringSleep", option_type(bool_type)], ["Notes", option_type(string_type)]]);
}

function BodilyFunctionsRecord_Validator_5C9DD176(record) {
    return Constraints_collectConstraintsErrors(ofArray([getRatingErrors(~~record.Rating), getNotesErrors(record.Notes)]));
}

export function BodilyFunctionsRecord_Default() {
    return new BodilyFunctionsRecord(0n, 1, 60, 0 & 0xFF, undefined, undefined);
}

export function BodilyFunctionsRecord__Validate(this$) {
    return BodilyFunctionsRecord_Validator_5C9DD176(this$);
}

export function BodilyFunctionsRecord__ValidateField_Z721C83C5(this$, name) {
    return tryFind(name, BodilyFunctionsRecord__Validate(this$));
}

export class PhysicalSearchOptions extends Record {
    constructor(DateFrom, DateTo, Notes) {
        super();
        this.DateFrom = DateFrom;
        this.DateTo = DateTo;
        this.Notes = Notes;
    }
}

export function PhysicalSearchOptions_$reflection() {
    return record_type("Omnicv.Shared.PhysicalAPI.PhysicalSearchOptions", [], PhysicalSearchOptions, () => [["DateFrom", option_type(class_type("System.DateTime"))], ["DateTo", option_type(class_type("System.DateTime"))], ["Notes", option_type(string_type)]]);
}

export function PhysicalSearchOptions__Validate(this$) {
    if (((this$.DateFrom == null) && (this$.DateTo == null)) && (this$.Notes == null)) {
        return "At least one parameter must be populated";
    }
    else if (((this$.DateFrom != null) && (this$.DateTo != null)) && (compare(this$.DateFrom, this$.DateTo) >= 0)) {
        return "From must be smaller than To";
    }
    else {
        return undefined;
    }
}

export class UnifiedRecord extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Weight", "BloodPressure", "BodilyFunctions", "Temperature"];
    }
}

export function UnifiedRecord_$reflection() {
    return union_type("Omnicv.Shared.PhysicalAPI.UnifiedRecord", [], UnifiedRecord, () => [[["Item", WeightRecord_$reflection()]], [["Item", BloodPressureRecord_$reflection()]], [["Item", BodilyFunctionsRecord_$reflection()]], [["Item", TemperatureRecord_$reflection()]]]);
}

export class RecordService$1 extends Record {
    constructor(Add, Edit, GetRecord, GetRecords, SearchRecords) {
        super();
        this.Add = Add;
        this.Edit = Edit;
        this.GetRecord = GetRecord;
        this.GetRecords = GetRecords;
        this.SearchRecords = SearchRecords;
    }
}

export function RecordService$1_$reflection(gen0) {
    return record_type("Omnicv.Shared.PhysicalAPI.RecordService`1", [gen0], RecordService$1, () => [["Add", lambda_type(EventInfo_$reflection(), lambda_type(gen0, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [EventInfo_$reflection()])))], ["Edit", lambda_type(EventInfo_$reflection(), lambda_type(gen0, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [EventInfo_$reflection()])))], ["GetRecord", lambda_type(int32_type, lambda_type(int64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [tuple_type(EventInfo_$reflection(), gen0)])))], ["GetRecords", lambda_type(int32_type, lambda_type(PagingOptions_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(tuple_type(EventInfo_$reflection(), gen0))])))], ["SearchRecords", lambda_type(int32_type, lambda_type(PhysicalSearchOptions_$reflection(), lambda_type(PagingOptions_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(tuple_type(EventInfo_$reflection(), gen0))]))))]]);
}

export class UnifiedService extends Record {
    constructor(GetRecords) {
        super();
        this.GetRecords = GetRecords;
    }
}

export function UnifiedService_$reflection() {
    return record_type("Omnicv.Shared.PhysicalAPI.UnifiedService", [], UnifiedService, () => [["GetRecords", lambda_type(int32_type, lambda_type(PagingOptions_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(tuple_type(EventInfo_$reflection(), UnifiedRecord_$reflection()))])))]]);
}

export function UnifiedService_RouteBuilder(_arg, m) {
    return toText(printf("/api/auth/mod/phys/unified/%s"))(m);
}

export class PhysicalModule {
    constructor() {
        this.initialTimestamp = parse("2021-11-13T19:53:00");
        this.latestTimestamp = this.initialTimestamp;
    }
    get Details() {
        const this$ = this;
        const Description = "Physical, body and well-being tracking module. Log your weight, height, blood pressure and other physical parameters and activities";
        return new ProductInformation("Omnicv.Physical", this$.initialTimestamp, this$.latestTimestamp, "Physical", Description, empty(), PhysicalModule__get_CurrentVersion(this$), singleton(defaultAuthors), defaultContacts, defaultWeb, undefined, undefined, new Changelog(1, [PhysicalModule__get_Changelog(this$)]));
    }
    RegisteredTypes() {
        return ofArray([WeightRecord_$reflection(), BloodPressureRecord_$reflection(), BodilyFunctionsRecord_$reflection(), TemperatureRecord_$reflection()]);
    }
}

export function PhysicalModule_$reflection() {
    return class_type("Omnicv.Shared.PhysicalAPI.PhysicalModule", undefined, PhysicalModule);
}

export function PhysicalModule_$ctor() {
    return new PhysicalModule();
}

export function PhysicalModule__get_Changelog(this$) {
    return singleton(new ChangelogEntry(this$.initialTimestamp, new Version(1, 0, 1, undefined), "Initial release"));
}

export function PhysicalModule__get_CurrentVersion(this$) {
    return head(PhysicalModule__get_Changelog(this$)).Version;
}

