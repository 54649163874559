import { Record, Union } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { list_type, array_type, uint8_type, record_type, option_type, uint32_type, union_type, string_type, class_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { Uri } from "../fable_modules/fable-library-js.4.19.3/Uri.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.3/List.js";

export class ContactType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Url", "Email", "Phone"];
    }
}

export function ContactType_$reflection() {
    return union_type("Omnicv.DataModule.ProductDetails.ContactType", [], ContactType, () => [[["Item", class_type("System.Uri")]], [["Item", string_type]], [["Item1", string_type], ["Item2", string_type]]]);
}

export class Version extends Record {
    constructor(Major, Minor, Patch, Build) {
        super();
        this.Major = Major;
        this.Minor = Minor;
        this.Patch = Patch;
        this.Build = Build;
    }
    toString() {
        const this$ = this;
        const matchValue = this$.Build;
        if (matchValue == null) {
            return toText(printf("%d.%d.%d"))(this$.Major)(this$.Minor)(this$.Patch);
        }
        else {
            const build = matchValue;
            return toText(printf("%d.%d.%d-%s"))(this$.Major)(this$.Minor)(this$.Patch)(build);
        }
    }
}

export function Version_$reflection() {
    return record_type("Omnicv.DataModule.ProductDetails.Version", [], Version, () => [["Major", uint32_type], ["Minor", uint32_type], ["Patch", uint32_type], ["Build", option_type(string_type)]]);
}

export class ChangelogEntry extends Record {
    constructor(Date$, Version, Details) {
        super();
        this.Date = Date$;
        this.Version = Version;
        this.Details = Details;
    }
}

export function ChangelogEntry_$reflection() {
    return record_type("Omnicv.DataModule.ProductDetails.ChangelogEntry", [], ChangelogEntry, () => [["Date", class_type("System.DateTime")], ["Version", Version_$reflection()], ["Details", string_type]]);
}

export class Graphics extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Image", "Video", "VideoLink"];
    }
}

export function Graphics_$reflection() {
    return union_type("Omnicv.DataModule.ProductDetails.Graphics", [], Graphics, () => [[["Item1", array_type(uint8_type)], ["Item2", string_type]], [["Item1", array_type(uint8_type)], ["Item2", string_type]], [["Item", class_type("System.Uri")]]]);
}

export class Changelog extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Text", "Entries"];
    }
}

export function Changelog_$reflection() {
    return union_type("Omnicv.DataModule.ProductDetails.Changelog", [], Changelog, () => [[["Item", string_type]], [["Item", list_type(ChangelogEntry_$reflection())]]]);
}

export class ProductInformation extends Record {
    constructor(Name, CreatedAt, LastUpdatedAt, Title, Description, Graphics, Version, Authors, Contacts, WebSite, UserGuide, Support, ChangeLog) {
        super();
        this.Name = Name;
        this.CreatedAt = CreatedAt;
        this.LastUpdatedAt = LastUpdatedAt;
        this.Title = Title;
        this.Description = Description;
        this.Graphics = Graphics;
        this.Version = Version;
        this.Authors = Authors;
        this.Contacts = Contacts;
        this.WebSite = WebSite;
        this.UserGuide = UserGuide;
        this.Support = Support;
        this.ChangeLog = ChangeLog;
    }
}

export function ProductInformation_$reflection() {
    return record_type("Omnicv.DataModule.ProductDetails.ProductInformation", [], ProductInformation, () => [["Name", string_type], ["CreatedAt", class_type("System.DateTime")], ["LastUpdatedAt", class_type("System.DateTime")], ["Title", string_type], ["Description", string_type], ["Graphics", list_type(Graphics_$reflection())], ["Version", Version_$reflection()], ["Authors", list_type(string_type)], ["Contacts", list_type(ContactType_$reflection())], ["WebSite", class_type("System.Uri")], ["UserGuide", option_type(class_type("System.Uri"))], ["Support", option_type(string_type)], ["ChangeLog", Changelog_$reflection()]]);
}

export const defaultWeb = Uri.create("https://www.omnicv.com");

export const defaultAuthors = "OMNICV.COM";

export const defaultContacts = ofArray([new ContactType(0, [defaultWeb]), new ContactType(1, ["info@omnicv.com"])]);

