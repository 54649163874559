import { record_type, lambda_type, list_type, tuple_type, option_type, union_type, string_type, class_type } from "../fable-library-js.4.19.3/Reflection.js";
import { FSharpRef, Record, Union } from "../fable-library-js.4.19.3/Types.js";
import { singleton as singleton_1, ofArray, filter, collect, skipWhile, takeWhile, map, getSlice, item, tryFindIndex } from "../fable-library-js.4.19.3/List.js";
import { FSharpChoice$2 } from "../fable-library-js.4.19.3/Choice.js";
import { choose, collect as collect_1, empty, map as map_1, singleton, append, delay, toList, tryPick } from "../fable-library-js.4.19.3/Seq.js";
import { curry2, defaultOf } from "../fable-library-js.4.19.3/Util.js";
import { tryGetValue } from "../fable-library-js.4.19.3/MapUtil.js";
import { map as map_2, some } from "../fable-library-js.4.19.3/Option.js";
import { split, replicate, join, printf, toText, replace } from "../fable-library-js.4.19.3/String.js";
import { MarkdownParagraph, MarkdownSpan } from "./MarkdownModel.fs.js";

export class FableTextWriter {
    constructor() {
        this.buffer = "";
    }
    toString() {
        const _ = this;
        return _.buffer;
    }
    Dispose() {
    }
}

export function FableTextWriter_$reflection() {
    return class_type("Fable.Formatting.Markdown.FableTextWriter", undefined, FableTextWriter);
}

export function FableTextWriter_$ctor() {
    return new FableTextWriter();
}

export class ParamKey extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ParamKey"];
    }
    toString() {
        const x = this;
        return x.fields[0];
    }
}

export function ParamKey_$reflection() {
    return union_type("Fable.Formatting.Markdown.ParamKey", [], ParamKey, () => [[["Item", string_type]]]);
}

export function FableTextWriter__Write_Z721C83C5(_, s) {
    _.buffer = (_.buffer + s);
}

export class MarkdownUtils_FormattingContext extends Record {
    constructor(Links, Newline, Substitutions, ResolveApiDocReference, DefineSymbol) {
        super();
        this.Links = Links;
        this.Newline = Newline;
        this.Substitutions = Substitutions;
        this.ResolveApiDocReference = ResolveApiDocReference;
        this.DefineSymbol = DefineSymbol;
    }
}

export function MarkdownUtils_FormattingContext_$reflection() {
    return record_type("Fable.Formatting.Markdown.MarkdownUtils.FormattingContext", [], MarkdownUtils_FormattingContext, () => [["Links", class_type("System.Collections.Generic.IDictionary`2", [string_type, tuple_type(string_type, option_type(string_type))])], ["Newline", string_type], ["Substitutions", list_type(tuple_type(ParamKey_$reflection(), string_type))], ["ResolveApiDocReference", lambda_type(string_type, option_type(tuple_type(string_type, string_type)))], ["DefineSymbol", string_type]]);
}

export function MarkdownUtils_isCode(_arg) {
    switch (_arg.tag) {
        case 2:
        case 3:
            return true;
        default:
            return false;
    }
}

export function MarkdownUtils_isCodeOutput(_arg) {
    if (_arg.tag === 13) {
        return true;
    }
    else {
        return false;
    }
}

export function MarkdownUtils_getExecutionCount(_arg) {
    let matchResult, executionCount;
    switch (_arg.tag) {
        case 2: {
            matchResult = 0;
            executionCount = _arg.fields[1];
            break;
        }
        case 3: {
            matchResult = 0;
            executionCount = _arg.fields[1];
            break;
        }
        default:
            matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return executionCount;
        default:
            return undefined;
    }
}

export function MarkdownUtils_getCode(_arg) {
    switch (_arg.tag) {
        case 2:
            return _arg.fields[0];
        case 3:
            return _arg.fields[0];
        default:
            throw new Error("unreachable");
    }
}

export function MarkdownUtils_getCodeOutput(_arg) {
    if (_arg.tag === 13) {
        return [_arg.fields[0], _arg.fields[1]];
    }
    else {
        throw new Error("unreachable");
    }
}

export function MarkdownUtils_splitParagraphs(paragraphs) {
    const firstCode = tryFindIndex(MarkdownUtils_isCode, paragraphs);
    let matchResult;
    if (firstCode == null) {
        matchResult = 1;
    }
    else if (firstCode === 0) {
        matchResult = 0;
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0: {
            const code = item(0, paragraphs);
            const codeLines = MarkdownUtils_getCode(code);
            const otherParagraphs = getSlice(1, undefined, paragraphs);
            const codeOutput = map(MarkdownUtils_getCodeOutput, takeWhile(MarkdownUtils_isCodeOutput, otherParagraphs));
            const otherParagraphs_1 = skipWhile(MarkdownUtils_isCodeOutput, otherParagraphs);
            return [new FSharpChoice$2(0, [[codeLines, codeOutput, MarkdownUtils_getExecutionCount(code)]]), otherParagraphs_1];
        }
        default:
            return [new FSharpChoice$2(1, [takeWhile((arg) => !MarkdownUtils_isCode(arg), paragraphs)]), skipWhile((arg_1) => !MarkdownUtils_isCode(arg_1), paragraphs)];
    }
}

/**
 * Lookup a specified key in a dictionary, possibly
 * ignoring newlines or spaces in the key.
 */
export function MarkdownUtils_$007CLookupKey$007C_$007C(dict, key) {
    return tryPick((key_1) => {
        let matchValue;
        let outArg = defaultOf();
        matchValue = [tryGetValue(dict, key_1, new FSharpRef(() => outArg, (v) => {
            outArg = v;
        })), outArg];
        if (matchValue[0]) {
            return some(matchValue[1]);
        }
        else {
            return undefined;
        }
    }, [key, replace(key, "\r\n", ""), replace(key, "\r\n", " "), replace(key, "\n", ""), replace(key, "\n", " ")]);
}

/**
 * Format a MarkdownSpan
 */
export function MarkdownUtils_formatSpan(ctx, span) {
    let matchResult, body, body_1, cmd, str, body_2, link;
    switch (span.tag) {
        case 10: {
            matchResult = 0;
            body = span.fields[0];
            break;
        }
        case 11: {
            matchResult = 1;
            body_1 = span.fields[0];
            break;
        }
        case 12: {
            matchResult = 2;
            cmd = span.fields[0];
            break;
        }
        case 0: {
            matchResult = 3;
            str = span.fields[0];
            break;
        }
        case 9: {
            matchResult = 4;
            break;
        }
        case 4: {
            matchResult = 5;
            break;
        }
        case 6: {
            const activePatternResult = MarkdownUtils_$007CLookupKey$007C_$007C(ctx.Links, span.fields[2]);
            if (activePatternResult != null) {
                matchResult = 6;
                body_2 = span.fields[0];
                link = activePatternResult[0];
            }
            else {
                matchResult = 6;
                body_2 = span.fields[0];
                link = span.fields[1];
            }
            break;
        }
        case 5: {
            matchResult = 6;
            body_2 = span.fields[0];
            link = span.fields[1];
            break;
        }
        default:
            matchResult = 7;
    }
    switch (matchResult) {
        case 0:
            return toText(printf("$%s$"))(body);
        case 1:
            return toText(printf("$$%s$$"))(body_1);
        case 2:
            return MarkdownUtils_formatSpans(ctx, cmd.Render());
        case 3:
            return str;
        case 4:
            return "\n";
        case 5:
            return "";
        case 6:
            return ((("[" + MarkdownUtils_formatSpans(ctx, body_2)) + "](") + link) + ")";
        default: {
            let matchResult_1, _body, _link;
            if (span.tag === 8) {
                const activePatternResult_1 = MarkdownUtils_$007CLookupKey$007C_$007C(ctx.Links, span.fields[2]);
                if (activePatternResult_1 != null) {
                    matchResult_1 = 0;
                    _body = span.fields[0];
                    _link = activePatternResult_1[0];
                }
                else {
                    matchResult_1 = 0;
                    _body = span.fields[0];
                    _link = span.fields[1];
                }
            }
            else {
                matchResult_1 = 1;
            }
            switch (matchResult_1) {
                case 0:
                    throw new Error("tbd - IndirectImage");
                default:
                    switch (span.tag) {
                        case 7:
                            return toText(printf("![%s](%s)"))(span.fields[0])(span.fields[1]);
                        case 2:
                            return ("**" + MarkdownUtils_formatSpans(ctx, span.fields[0])) + "**";
                        case 1:
                            return ("`" + span.fields[0]) + "`";
                        case 3:
                            return ("**" + MarkdownUtils_formatSpans(ctx, span.fields[0])) + "**";
                        default:
                            throw new Error("Match failure: Fable.Formatting.Markdown.MarkdownSpan");
                    }
            }
        }
    }
}

/**
 * Format a list of MarkdownSpan
 */
export function MarkdownUtils_formatSpans(ctx, spans) {
    return join("", map((span) => MarkdownUtils_formatSpan(ctx, span), spans));
}

/**
 * Format a MarkdownParagraph
 */
export function MarkdownUtils_formatParagraph(ctx, paragraph) {
    return toList(delay(() => {
        let matchValue;
        return (paragraph.tag === 7) ? append(singleton(toText(printf("\\begin{%s}"))(paragraph.fields[0])), delay(() => append(map_1((line) => line, paragraph.fields[1]), delay(() => append(singleton(toText(printf("\\end{%s}"))(paragraph.fields[0])), delay(() => singleton(""))))))) : ((paragraph.tag === 0) ? append(singleton((replicate(paragraph.fields[0], "#") + " ") + MarkdownUtils_formatSpans(ctx, paragraph.fields[1])), delay(() => singleton(""))) : ((paragraph.tag === 1) ? append(singleton(join("", toList(delay(() => map_1((span) => MarkdownUtils_formatSpan(ctx, span), paragraph.fields[0]))))), delay(() => singleton(""))) : ((paragraph.tag === 8) ? append(singleton("-----------------------"), delay(() => singleton(""))) : ((paragraph.tag === 2) ? append(singleton(paragraph.fields[0]), delay(() => singleton(""))) : ((paragraph.tag === 4) ? ((paragraph.fields[0].tag === 1) ? singleton(join("\n", collect((ps) => toList(delay(() => map_1((p) => join("", MarkdownUtils_formatParagraph(ctx, p)), ps))), paragraph.fields[1]))) : append(singleton(toText(printf("// can\'t yet format %0A to pynb markdown"))(paragraph)), delay(() => singleton("")))) : ((paragraph.tag === 9) ? append((matchValue = paragraph.fields[0], (matchValue == null) ? (empty()) : singleton(join(" | ", collect((hs) => toList(delay(() => map_1((h) => join("", MarkdownUtils_formatParagraph(ctx, h)), hs))), matchValue)))), delay(() => append(singleton(join(" | ", toList(delay(() => map_1((a) => ((a.tag === 2) ? ":---:" : ((a.tag === 1) ? "---:" : ((a.tag === 3) ? "---" : ":---"))), paragraph.fields[1]))))), delay(() => {
            const replaceEmptyWith = (x, s) => {
                let matchResult;
                if (s === "") {
                    matchResult = 0;
                }
                else if (s === defaultOf()) {
                    matchResult = 0;
                }
                else {
                    matchResult = 1;
                }
                switch (matchResult) {
                    case 0:
                        return x;
                    default:
                        return s;
                }
            };
            return append(singleton(join("\n", toList(delay(() => map_1((r) => {
                let source_2;
                return join(" | ", (source_2 = toList(delay(() => collect_1((ps_1) => {
                    const x_1 = toList(delay(() => map_1((p_1) => {
                        let source;
                        return join("", (source = MarkdownUtils_formatParagraph(ctx, p_1), choose(curry2(replaceEmptyWith)(""), source)));
                    }, ps_1)));
                    return singleton(join("<br />", choose(curry2(replaceEmptyWith)(""), x_1)));
                }, r))), choose(curry2(replaceEmptyWith)("&#32;"), source_2)));
            }, paragraph.fields[2]))))), delay(() => singleton("\n")));
        })))) : ((paragraph.tag === 13) ? ((paragraph.fields[1] === "text/html") ? append(singleton(paragraph.fields[0].trim()), delay(() => singleton(""))) : append(singleton("```"), delay(() => append(singleton(paragraph.fields[0]), delay(() => append(singleton("```"), delay(() => singleton("")))))))) : ((paragraph.tag === 10) ? map((tuple) => tuple[0], paragraph.fields[0]) : append(singleton(toText(printf("// can\'t yet format %0A to pynb markdown"))(paragraph)), delay(() => singleton("")))))))))));
    }));
}

export function MarkdownUtils_formatFsxCode(ctx, code) {
    const sym = ctx.DefineSymbol;
    const sym1 = toText(printf("#if %s"))(sym);
    const sym2 = toText(printf("#endif // %s"))(sym);
    return join(ctx.Newline, filter((line) => {
        if (line.trim() !== sym1) {
            return line.trim() !== sym2;
        }
        else {
            return false;
        }
    }, ofArray(split(replace(code, "\r\n", "\n"), ["\n"], undefined, 0))));
}

export function MarkdownUtils_applySubstitutionsInText(ctx, text) {
    return text;
}

export function MarkdownUtils_applyCodeReferenceResolver(ctx, code, range) {
    const matchValue = ctx.ResolveApiDocReference(code);
    if (matchValue != null) {
        return new MarkdownSpan(5, [singleton_1(new MarkdownSpan(0, [matchValue[0], range])), matchValue[1], undefined, range]);
    }
    else {
        return new MarkdownSpan(1, [code, range]);
    }
}

export function MarkdownUtils_mapText(f, _arg, text) {
    return f(text);
}

export function MarkdownUtils_mapInlineCode(_arg, f, code, range) {
    return f([code, range]);
}

export function MarkdownUtils_mapSpans(f_, f__1, md) {
    const f = [f_, f__1];
    return map((_arg) => {
        switch (_arg.tag) {
            case 2:
                return new MarkdownSpan(2, [MarkdownUtils_mapSpans(f[0], f[1], _arg.fields[0]), _arg.fields[1]]);
            case 3:
                return new MarkdownSpan(3, [MarkdownUtils_mapSpans(f[0], f[1], _arg.fields[0]), _arg.fields[1]]);
            case 4:
                return new MarkdownSpan(4, [MarkdownUtils_mapText(f[0], f[1], _arg.fields[0]), _arg.fields[1]]);
            case 5:
                return new MarkdownSpan(5, [MarkdownUtils_mapSpans(f[0], f[1], _arg.fields[0]), MarkdownUtils_mapText(f[0], f[1], _arg.fields[1]), map_2((text_1) => MarkdownUtils_mapText(f[0], f[1], text_1), _arg.fields[2]), _arg.fields[3]]);
            case 6:
                return new MarkdownSpan(6, [MarkdownUtils_mapSpans(f[0], f[1], _arg.fields[0]), _arg.fields[1], _arg.fields[2], _arg.fields[3]]);
            case 7:
                return new MarkdownSpan(7, [MarkdownUtils_mapText(f[0], f[1], _arg.fields[0]), MarkdownUtils_mapText(f[0], f[1], _arg.fields[1]), map_2((text_2) => MarkdownUtils_mapText(f[0], f[1], text_2), _arg.fields[2]), _arg.fields[3]]);
            case 8:
                return new MarkdownSpan(8, [MarkdownUtils_mapText(f[0], f[1], _arg.fields[0]), _arg.fields[1], _arg.fields[2], _arg.fields[3]]);
            case 9:
                return new MarkdownSpan(9, [_arg.fields[0]]);
            case 1:
                return MarkdownUtils_mapInlineCode(f[0], f[1], _arg.fields[0], _arg.fields[1]);
            case 10:
                return new MarkdownSpan(10, [_arg.fields[0], _arg.fields[1]]);
            case 11:
                return new MarkdownSpan(11, [_arg.fields[0], _arg.fields[1]]);
            case 12:
                return new MarkdownSpan(12, [_arg.fields[0], _arg.fields[1]]);
            default:
                return new MarkdownSpan(0, [MarkdownUtils_mapText(f[0], f[1], _arg.fields[0]), _arg.fields[1]]);
        }
    }, md);
}

export function MarkdownUtils_mapParagraphs(f_, f__1, md) {
    const f = [f_, f__1];
    return map((_arg) => {
        switch (_arg.tag) {
            case 1:
                return new MarkdownParagraph(1, [MarkdownUtils_mapSpans(f[0], f[1], _arg.fields[0]), _arg.fields[1]]);
            case 2:
                return new MarkdownParagraph(2, [MarkdownUtils_mapText(f[0], f[1], _arg.fields[0]), _arg.fields[1], _arg.fields[2], _arg.fields[3], _arg.fields[4]]);
            case 13:
                return new MarkdownParagraph(13, [_arg.fields[0], _arg.fields[1], _arg.fields[2]]);
            case 4:
                return new MarkdownParagraph(4, [_arg.fields[0], map((md_1) => MarkdownUtils_mapParagraphs(f[0], f[1], md_1), _arg.fields[1]), _arg.fields[2]]);
            case 5:
                return new MarkdownParagraph(5, [MarkdownUtils_mapParagraphs(f[0], f[1], _arg.fields[0]), _arg.fields[1]]);
            case 6:
                return new MarkdownParagraph(6, [MarkdownUtils_mapSpans(f[0], f[1], _arg.fields[0]), _arg.fields[1]]);
            case 7:
                return new MarkdownParagraph(7, [_arg.fields[0], map((text) => MarkdownUtils_mapText(f[0], f[1], text), _arg.fields[1]), _arg.fields[2]]);
            case 8:
                return new MarkdownParagraph(8, [_arg.fields[0], _arg.fields[1]]);
            case 12:
                return new MarkdownParagraph(12, [map((text_1) => MarkdownUtils_mapText(f[0], f[1], text_1), _arg.fields[0]), _arg.fields[1]]);
            case 9:
                return new MarkdownParagraph(9, [map_2((list) => map((md_2) => MarkdownUtils_mapParagraphs(f[0], f[1], md_2), list), _arg.fields[0]), _arg.fields[1], map((list_1) => map((md_3) => MarkdownUtils_mapParagraphs(f[0], f[1], md_3), list_1), _arg.fields[2]), _arg.fields[3]]);
            case 10:
                return new MarkdownParagraph(10, [map((tupledArg) => [MarkdownUtils_mapText(f[0], f[1], tupledArg[0]), tupledArg[1]], _arg.fields[0]), _arg.fields[1]]);
            case 3:
                return new MarkdownParagraph(3, [MarkdownUtils_mapText(f[0], f[1], _arg.fields[0]), _arg.fields[1], _arg.fields[2]]);
            case 11:
                return new MarkdownParagraph(11, [_arg.fields[0], _arg.fields[1]]);
            default:
                return new MarkdownParagraph(0, [_arg.fields[0], MarkdownUtils_mapSpans(f[0], f[1], _arg.fields[1]), _arg.fields[2]]);
        }
    }, md);
}

export function MarkdownUtils_applySubstitutionsInMarkdown(ctx, md) {
    return MarkdownUtils_mapParagraphs((text) => MarkdownUtils_applySubstitutionsInText(ctx, text), (tupledArg) => MarkdownUtils_applyCodeReferenceResolver(ctx, tupledArg[0], tupledArg[1]), md);
}

