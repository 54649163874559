import { toString, Union } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { newGuid } from "../../fable_modules/fable-library-js.4.19.3/Guid.js";
import { createElement } from "react";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.Bulma.3.0.0/ElementBuilders.fs.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";

export class Class extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Link", "Info", "Success", "Warning", "Danger"];
    }
}

export function Class_$reflection() {
    return union_type("Omnicv.Client.Components.Notification.Class", [], Class, () => [[], [], [], [], []]);
}

export function Class__get_ToClassName(this$) {
    return "is-" + toString(this$).toLocaleLowerCase();
}

function closeById(id) {
    const contentElement = document.getElementById(id);
    contentElement.hidden = true;
}

export function closeable(text, notificationClass) {
    let elems;
    let id;
    let copyOfStruct = newGuid();
    id = copyOfStruct;
    return createElement("div", createObj(ofArray([["id", id], ["className", "notification " + Class__get_ToClassName(notificationClass)], (elems = [createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "delete"], ["onClick", (_arg) => {
        closeById(id);
    }]])))), text], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

