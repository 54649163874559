import { FSharpResult$2 } from "../fable-library-js.4.19.3/Result.js";
import { cons, getSlice, append, length, isEmpty, tail } from "../fable-library-js.4.19.3/List.js";

export function Result_andThen(callback, result) {
    if (result.tag === 1) {
        return new FSharpResult$2(1, [result.fields[0]]);
    }
    else {
        return callback(result.fields[0]);
    }
}

/**
 * Returns a list that drops N elements of the original list and then yields the
 * remaining elements of the list.
 */
export function List_drop(count, source) {
    const loop = (i_mut, lst_mut) => {
        loop:
        while (true) {
            const i = i_mut, lst = lst_mut;
            let matchResult, x;
            if (isEmpty(lst)) {
                matchResult = 0;
                x = lst;
            }
            else if (i === 0) {
                matchResult = 0;
                x = lst;
            }
            else {
                matchResult = 1;
            }
            switch (matchResult) {
                case 0:
                    return x;
                default: {
                    i_mut = (i - 1);
                    lst_mut = tail(lst);
                    continue loop;
                }
            }
            break;
        }
    };
    if (count > 0) {
        return loop(count, source);
    }
    else {
        return source;
    }
}

/**
 * Updates the value of an item in a list
 */
export function List_setAt(i, x, lst) {
    if ((length(lst) > i) && (i >= 0)) {
        return append(getSlice(0, i - 1, lst), cons(x, getSlice(i + 1, undefined, lst)));
    }
    else {
        return lst;
    }
}

