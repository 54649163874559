import { Union } from "../../fable-library-js.4.19.3/Types.js";
import { union_type, list_type, class_type, string_type } from "../../fable-library-js.4.19.3/Reflection.js";
import { Common_GenericOptions__AddCaseName_1505, Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddProps_Z4E55E0BD, Common_GenericOptions__RemoveClass_Z721C83C5, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7, Modifier_IModifier_$reflection } from "../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library-js.4.19.3/MapUtil.js";
import { cons } from "../../fable-library-js.4.19.3/List.js";

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["is-1", "is-2", "is-3", "is-4", "is-5", "is-6", "subtitle", "is-spaced", "CustomClass", "Props", "Modifiers"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.Heading.Option", [], Option, () => [[], [], [], [], [], [], [], [], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

export function title(element, options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 6:
                return Common_GenericOptions__AddClass_Z721C83C5(Common_GenericOptions__RemoveClass_Z721C83C5(result, "title"), "subtitle");
            case 9:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 8:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 10:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddCaseName_1505(result, option);
        }
    }, "title"), element, children);
}

/**
 * Generate <h1 class="title is-1"></h1>
 * Class can be `subtitle` if you pass `Heading.IsSubtitle`
 */
export function h1(options) {
    return (children_3) => title((props, children) => react.createElement("h1", keyValueList(props, 1), ...children), cons(new Option(0, []), options), children_3);
}

/**
 * Generate <h2 class="title is-2"></h2>
 * Class can be `subtitle` if you pass `Heading.IsSubtitle`
 */
export function h2(options) {
    return (children_3) => title((props, children) => react.createElement("h2", keyValueList(props, 1), ...children), cons(new Option(1, []), options), children_3);
}

/**
 * Generate <h3 class="title is-3"></h3>
 * Class can be `subtitle` if you pass `Heading.IsSubtitle`
 */
export function h3(options) {
    return (children_3) => title((props, children) => react.createElement("h3", keyValueList(props, 1), ...children), cons(new Option(2, []), options), children_3);
}

/**
 * Generate <h4 class="title is-4"></h4>
 * Class can be `subtitle` if you pass `Heading.IsSubtitle`
 */
export function h4(options) {
    return (children_3) => title((props, children) => react.createElement("h4", keyValueList(props, 1), ...children), cons(new Option(3, []), options), children_3);
}

/**
 * Generate <h5 class="title is-5"></h5>
 * Class can be `subtitle` if you pass `Heading.IsSubtitle`
 */
export function h5(options) {
    return (children_3) => title((props, children) => react.createElement("h5", keyValueList(props, 1), ...children), cons(new Option(4, []), options), children_3);
}

/**
 * Generate <h6 class="title is-6"></h6>
 * Class can be `subtitle` if you pass `Heading.IsSubtitle`
 */
export function h6(options) {
    return (children_3) => title((props, children) => react.createElement("h6", keyValueList(props, 1), ...children), cons(new Option(5, []), options), children_3);
}

/**
 * Generate <p class="title"></p>
 * Class can be `subtitle` if you pass `Heading.IsSubtitle`
 */
export function p(opts, children) {
    return title((props, children_1) => react.createElement("p", keyValueList(props, 1), ...children_1), opts, children);
}

