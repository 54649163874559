export function objectFieldGetter(name, obj) {
    return obj[name];
}

export function objectFieldSetter(name, obj, newValue) {
    var cloneObj = {...obj};
    cloneObj[name] = newValue;
    return cloneObj;
}

function resolveProperty(obj, name) {
    if (obj.hasOwnProperty(name)) {
        return obj[name];
    }
    else if (obj.hasOwnProperty("fields")) {
        return resolveProperty(obj["fields"], name);
    }
    else if (Array.isArray(obj)) {
        return resolveProperty(obj[0], name);
    }
    else {
        return null;
    }
}

export function getId(obj) {
    return obj.Id;
}

export function getExerciseId(obj) {
    return resolveProperty(obj, "ExerciseId");
}

export function getName(obj) {
    return resolveProperty(obj, "Name");
}

export function getProperties(obj) {
    if (obj.hasOwnProperty("fields")) {
        return getProperties(obj["fields"]);
    }
    else if (Array.isArray(obj)) {
        return getProperties(obj[0]);
    }

    var names = Array();
    var values = Array();
    for (var name in obj) {
        if (obj.hasOwnProperty(name)) {
            var value = obj[name];
            names.push(name);
            if (value != null)
                values.push(value.toString());
            else
                values.push(null)
        }
    }
    var result = {
        "Names": names,
        "Values": values
    };
    return result;
}