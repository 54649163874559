import { format } from "../fable_modules/fable-library-js.4.19.3/String.js";

export function offsetToUtcZone(utcOffsetMins) {
    if (utcOffsetMins === 0) {
        return "UTC";
    }
    else {
        const hours = ~~(Math.abs(utcOffsetMins) / 60) | 0;
        const sign = (utcOffsetMins > 0) ? "+" : "-";
        const minutes = (Math.abs(utcOffsetMins) - (hours * 60)) | 0;
        if (minutes > 0) {
            return format("UTC{0}{1:D2}:{2:D2}", sign, hours, minutes);
        }
        else {
            return format("UTC{0}{1:D2}", sign, hours);
        }
    }
}

export function displayUtcOffsetOpt(utcOffsetOpt) {
    if (utcOffsetOpt == null) {
        return "N/A";
    }
    else if (utcOffsetOpt === 0) {
        return "UTC";
    }
    else {
        const utcOffsetMins = utcOffsetOpt | 0;
        return offsetToUtcZone(utcOffsetMins);
    }
}

