import * as common from "../../src/javascript/common.js";
import { collect, filter, empty as empty_2, ofArray, map, singleton } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { Record, Union } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { tuple_type, unit_type, record_type, class_type, bool_type, option_type, string_type, union_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Session__GetActiveWorkoutRecord, Icons, Session__GetCurrentName, Session__SetActiveWorkoutRecord_Z2D8A2414, Session__GetCurrentProfileId, Session_$reflection } from "../Omnicv.Client.Common/SharedView.js";
import { createObj, comparePrimitives, equals } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { EventInfo_$reflection } from "../Omnicv.Shared.Common/CoreEventInfo.js";
import { WorkoutRecord_$reflection } from "../Omnicv.Shared/FitnessAPI.js";
import { Cmd_none, Cmd_batch, Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { map as map_1, toList as toList_1, FSharpMap__Add, empty } from "../fable_modules/fable-library-js.4.19.3/Map.js";
import { value as value_47, some } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { Router_modifyLocation, Route, Router_newUrl } from "../Omnicv.Client.Common/Router.js";
import { op_Subtraction, compare } from "../fable_modules/fable-library-js.4.19.3/Date.js";
import { exnToDisplayString } from "../Omnicv.Client.Common/Utils.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { fitnessService } from "../Pages/Fitness/FitnessCommon.js";
import { commonAuthService } from "../Omnicv.Client.Common/CommonServer.js";
import { createElement } from "react";
import { Spacing_Amount, Spacing_TypeAndDirection, Modifier_IModifier, Color_IColor } from "../fable_modules/Fulma.3.0.0/Common.fs.js";
import { last, item } from "../fable_modules/fable-library-js.4.19.3/Array.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { IconType__ToIcon } from "../Omnicv.Client.Common/ClientModule.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { split, format, join } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { Registry$1__get_Modules } from "../Omnicv.Shared/ModulesRegistration.js";
import { clientModuleRegistry } from "../ClientRegistration.js";
import { Router_href } from "../Omnicv.Client.Common/Router.js";
import { List_Option, list as list_3, Option, button } from "../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { icon } from "../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_i } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Icons__ToIcon } from "../Omnicv.Client.Common/SharedView.js";
import { seconds, minutes, hours } from "../fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { Class, closeable } from "../Omnicv.Client.Common/Components/Notification.js";
import { Config_releaseVersion } from "../Omnicv.Client.Common/Extensions.js";

const soundPath = "/public/sounds/beep-03.mp3";

const soundAudioContext = common.createAudio(soundPath);

const permissionsToCheck = singleton("geolocation");

export class LogInState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["LoggedIn", "PendingLogOut", "LoggedOut"];
    }
}

export function LogInState_$reflection() {
    return union_type("Omnicv.Client.Components.Navigation.LogInState", [], LogInState, () => [[], [], []]);
}

export class Model extends Record {
    constructor(LogInState, Message, Session, InProgress, CurrentTimestamp, CurrentTimerExpiry, CheckedPermissions) {
        super();
        this.LogInState = LogInState;
        this.Message = Message;
        this.Session = Session;
        this.InProgress = InProgress;
        this.CurrentTimestamp = CurrentTimestamp;
        this.CurrentTimerExpiry = CurrentTimerExpiry;
        this.CheckedPermissions = CheckedPermissions;
    }
}

export function Model_$reflection() {
    return record_type("Omnicv.Client.Components.Navigation.Model", [], Model, () => [["LogInState", LogInState_$reflection()], ["Message", option_type(string_type)], ["Session", Session_$reflection()], ["InProgress", bool_type], ["CurrentTimestamp", class_type("System.DateTime")], ["CurrentTimerExpiry", option_type(class_type("System.DateTime"))], ["CheckedPermissions", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, class_type("Browser.Types.PermissionStatus")])]]);
}

export function Model__IsLoggedIn(this$) {
    return !equals(this$.LogInState, new LogInState(2, []));
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["LogOutClicked", "LogOutSucceeded", "Failure", "Refresh", "TimerUpdate", "ConcludeActiveWorkoutRecord", "DeleteActiveWorkoutRecord", "ActiveWorkoutRecordCloseSuccess", "UpdateActiveWorkoutRecord", "StartTimer", "StopTimer", "PermissionStatus"];
    }
}

export function Msg_$reflection() {
    return union_type("Omnicv.Client.Components.Navigation.Msg", [], Msg, () => [[], [["Item", string_type]], [["Item", class_type("System.Exception")]], [["Item", Session_$reflection()]], [["Item", class_type("System.DateTime")]], [], [], [["Item", unit_type]], [["Item", option_type(tuple_type(EventInfo_$reflection(), WorkoutRecord_$reflection()))]], [["Item", class_type("System.DateTime")]], [], [["Item1", string_type], ["Item2", class_type("Browser.Types.PermissionStatus")]]]);
}

function queryPermission(permission) {
    const descriptor_1 = {
        name: permission,
    };
    const pr = navigator.permissions.query(descriptor_1);
    return pr.then((status) => [permission, status]);
}

function queryPermissionCmd(permission) {
    return Cmd_OfPromise_either(queryPermission, permission, (tupledArg) => (new Msg(11, [tupledArg[0], tupledArg[1]])), (Item_2) => (new Msg(2, [Item_2])));
}

export function init(session) {
    return [new Model(session.LoggedIn ? (new LogInState(0, [])) : (new LogInState(2, [])), undefined, session, false, common.getNow(), undefined, empty({
        Compare: comparePrimitives,
    })), Cmd_batch(map(queryPermissionCmd, permissionsToCheck))];
}

export function update(session, msg, model) {
    let matchValue, time;
    switch (msg.tag) {
        case 1: {
            console.log(some(`Updating session logged out, msg: ${msg.fields[0]}`));
            return [new Model(new LogInState(2, []), "Logged out successfully", model.Session, model.InProgress, model.CurrentTimestamp, model.CurrentTimerExpiry, model.CheckedPermissions), Router_newUrl(new Route(0, []))];
        }
        case 4:
            return [new Model(model.LogInState, model.Message, model.Session, model.InProgress, msg.fields[0], (matchValue = model.CurrentTimerExpiry, (matchValue != null) ? ((time = matchValue, (compare(common.getNow(), time) >= 0) ? ((soundAudioContext.play(), undefined)) : model.CurrentTimerExpiry)) : undefined), model.CheckedPermissions), Cmd_none()];
        case 9:
            return [new Model(model.LogInState, model.Message, model.Session, model.InProgress, model.CurrentTimestamp, msg.fields[0], model.CheckedPermissions), Cmd_none()];
        case 10:
            return [new Model(model.LogInState, model.Message, model.Session, model.InProgress, model.CurrentTimestamp, undefined, model.CheckedPermissions), Cmd_none()];
        case 2:
            return [new Model(new LogInState(0, []), exnToDisplayString(msg.fields[0]), model.Session, model.InProgress, model.CurrentTimestamp, model.CurrentTimerExpiry, model.CheckedPermissions), Cmd_none()];
        case 3: {
            const session_1 = msg.fields[0];
            return [new Model(session_1.LoggedIn ? (new LogInState(0, [])) : (new LogInState(2, [])), undefined, session_1, model.InProgress, model.CurrentTimestamp, model.CurrentTimerExpiry, model.CheckedPermissions), Cmd_none()];
        }
        case 5:
            return [new Model(model.LogInState, model.Message, model.Session, true, model.CurrentTimestamp, model.CurrentTimerExpiry, model.CheckedPermissions), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, fitnessService.ConcludeActiveWorkoutRecord, Session__GetCurrentProfileId(session), () => (new Msg(7, [undefined])), (Item_3) => (new Msg(2, [Item_3])))];
        case 6:
            return [new Model(model.LogInState, model.Message, model.Session, true, model.CurrentTimestamp, model.CurrentTimerExpiry, model.CheckedPermissions), Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, fitnessService.DeleteActiveWorkoutRecord, Session__GetCurrentProfileId(session), () => (new Msg(7, [undefined])), (Item_5) => (new Msg(2, [Item_5])))];
        case 7:
            return [new Model(model.LogInState, model.Message, model.Session, false, model.CurrentTimestamp, model.CurrentTimerExpiry, model.CheckedPermissions), singleton((dispatch) => {
                dispatch(new Msg(8, [undefined]));
            })];
        case 8:
            return [new Model(model.LogInState, model.Message, Session__SetActiveWorkoutRecord_Z2D8A2414(model.Session, msg.fields[0]), model.InProgress, model.CurrentTimestamp, model.CurrentTimerExpiry, model.CheckedPermissions), Cmd_none()];
        case 11:
            return [new Model(model.LogInState, model.Message, model.Session, model.InProgress, model.CurrentTimestamp, model.CurrentTimerExpiry, FSharpMap__Add(model.CheckedPermissions, msg.fields[0], msg.fields[1])), Cmd_none()];
        default:
            if (!equals(model.LogInState, new LogInState(0, []))) {
                return [model, Cmd_none()];
            }
            else {
                return [new Model(new LogInState(1, []), model.Message, model.Session, model.InProgress, model.CurrentTimestamp, model.CurrentTimerExpiry, model.CheckedPermissions), Cmd_OfAsyncWith_either((x) => {
                    Cmd_OfAsync_start(x);
                }, commonAuthService.Logout, undefined, (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(2, [Item_1])))];
            }
    }
}

const burgerLine = createElement("span", {
    "aria-hidden": true,
});

const mainNavBarId = "mainNavBar";

const profileColours = [new Color_IColor(5, []), new Color_IColor(6, []), new Color_IColor(7, []), new Color_IColor(4, []), new Color_IColor(16, [])];

function getProfileColour(profileId) {
    return item(profileId % profileColours.length, profileColours);
}

export function clientModuleDescriptorToMenu(session, clientModule) {
    return createElement("a", createObj(toList(delay(() => append(singleton_1(["className", "navbar-item"]), delay(() => append((clientModule.Descriptor().View != null) ? singleton_1(["onClick", (_arg) => {
        Router_modifyLocation(value_47(clientModule.Descriptor().View)(session));
    }]) : empty_1(), delay(() => {
        let elems;
        return singleton_1((elems = [IconType__ToIcon(clientModule.Descriptor().Icon), createElement("span", {
            children: " ",
        }), createElement("span", {
            children: clientModule.Details.Title,
        })], ["children", reactApi.Children.toArray(Array.from(elems))]));
    }))))))));
}

export function descriptorButton(descriptor) {
    return createElement("a", createObj(toList(delay(() => append(singleton_1(["className", "navbar-item"]), delay(() => append((descriptor.Actions.NewNow != null) ? singleton_1(["onClick", (_arg) => {
        Router_modifyLocation(value_47(descriptor.Actions.NewNow));
    }]) : empty_1(), delay(() => {
        let elems;
        return singleton_1((elems = [IconType__ToIcon(descriptor.Icon)], ["children", reactApi.Children.toArray(Array.from(elems))]));
    }))))))));
}

function viewMainNavBar(model, dispatch) {
    let elems_7, elems_6, elems_3, elems_4, elems, name_2;
    const isLoggedIn = Model__IsLoggedIn(model);
    const navbarBandChildren = ofArray([createElement("a", {
        className: "navbar-item",
        children: "OmniCV",
    }), createElement("a", {
        className: "navbar-burger",
        "aria-label": "menu",
        role: join(" ", ["button"]),
        "aria-expanded": false,
        "data-target": mainNavBarId,
        children: reactApi.Children.toArray([burgerLine, burgerLine, burgerLine]),
    })]);
    return createElement("nav", createObj(ofArray([["className", join(" ", ["navbar", "is-primary"])], ["role", join(" ", ["navigation"])], ["aria-label", "main navigation"], (elems_7 = [createElement("div", {
        className: "navbar-brand",
        children: reactApi.Children.toArray(Array.from(navbarBandChildren)),
    }), createElement("div", createObj(ofArray([["className", "navbar-menu"], ["id", mainNavBarId], (elems_6 = [createElement("div", createObj(ofArray([["className", "navbar-start"], (elems_3 = (isLoggedIn ? map((clientModule) => clientModuleDescriptorToMenu(model.Session, clientModule), Registry$1__get_Modules(clientModuleRegistry)) : singleton(createElement("a", createObj(ofArray([["className", "navbar-item"], Router_href(new Route(0, [])), ["children", "Home"]]))))), ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", "navbar-end"], (elems_4 = ofArray([createElement("a", createObj(ofArray([["className", "navbar-item"], Router_href(new Route(8, [])), ["children", "Contact"]]))), createElement("a", createObj(ofArray([["className", "navbar-item"], Router_href(new Route(2, [])), ["children", "About"]]))), createElement("div", createObj(ofArray([["className", "navbar-item"], (elems = (isLoggedIn ? ((name_2 = Session__GetCurrentName(model.Session), ofArray([button(ofArray([new Option(0, [getProfileColour(Session__GetCurrentProfileId(model.Session))]), new Option(18, [(_arg) => {
        Router_modifyLocation(new Route(4, []));
    }])]), ofArray([icon(empty_2(), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(8, []))), []))), createElement("span", {
        children: reactApi.Children.toArray([name_2]),
    })])), button(ofArray([new Option(0, [new Color_IColor(4, [])]), new Option(18, [(_arg_1) => {
        dispatch(new Msg(0, []));
    }])]), ofArray([icon(empty_2(), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(10, []))), []))), createElement("span", {
        children: reactApi.Children.toArray(["Logout"]),
    })]))]))) : singleton(button(ofArray([new Option(0, [new Color_IColor(4, [])]), new Option(18, [(_arg_2) => {
        Router_modifyLocation(new Route(3, []));
    }])]), ofArray([icon(empty_2(), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(9, []))), []))), createElement("span", {
        children: reactApi.Children.toArray(["Login"]),
    })])))), ["children", reactApi.Children.toArray(Array.from(elems))])])))]), ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", reactApi.Children.toArray(Array.from(elems_7))])])));
}

function displayTimer(model, dispatch) {
    if ((model.CurrentTimerExpiry != null) && (compare(value_47(model.CurrentTimerExpiry), model.CurrentTimestamp) > 0)) {
        const now = common.getNow();
        const duration = op_Subtraction(value_47(model.CurrentTimerExpiry), now);
        return list_3(ofArray([new List_Option(8, [ofArray([new Modifier_IModifier(0, [new Color_IColor(11, [])]), new Modifier_IModifier(29, [new Spacing_TypeAndDirection(7, []), new Spacing_Amount(2, [])])])]), new List_Option(1, [])]), ofArray([button(ofArray([new Option(0, [new Color_IColor(17, [])]), new Option(4, []), new Option(16, [model.InProgress])]), toList(delay(() => {
            const durationStr = format("{0:D2}:{1:D2}:{2:D2}", hours(duration), minutes(duration), seconds(duration));
            return singleton_1(createElement("span", {
                children: reactApi.Children.toArray([durationStr]),
            }));
        }))), button(ofArray([new Option(0, [new Color_IColor(7, [])]), new Option(4, []), new Option(16, [model.InProgress]), new Option(18, [(_arg) => {
            dispatch(new Msg(10, []));
        }])]), singleton(icon(empty_2(), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(19, []))), [])))))]));
    }
    else {
        return defaultOf();
    }
}

function displayFavourites(model) {
    if (Model__IsLoggedIn(model)) {
        return list_3(empty_2(), map(descriptorButton, filter((x_1) => x_1.IsFavourite, collect((x) => x.EntryDescriptors(), Registry$1__get_Modules(clientModuleRegistry)))));
    }
    else {
        return defaultOf();
    }
}

function handlePermissionCheckState(dispatch, permission, status) {
    if (status.onchange == null) {
        console.log(some(`Updating onChange event listener for permission: ${permission}`));
        status.onchange = ((_arg) => {
            console.warn(some(`Permission ${permission} state changed to ${status.state}`));
            dispatch(new Msg(11, [permission, status]));
        });
    }
    if (status.state === "denied") {
        return closeable(`Denied permission: ${permission}`, new Class(3, []));
    }
    else {
        return defaultOf();
    }
}

function displayPermissionsWarnings(dispatch, model) {
    return map((tuple) => tuple[1], toList_1(map_1((permission, status) => handlePermissionCheckState(dispatch, permission, status), model.CheckedPermissions)));
}

function displayVersionWarning(model) {
    if (model.Session.UserInfo != null) {
        const serverVersion = last(split(value_47(model.Session.UserInfo).ServerVersion, ["/"], undefined, 0));
        if ((Config_releaseVersion() !== serverVersion) && (Config_releaseVersion() !== "development")) {
            return closeable(`Client version '${Config_releaseVersion()}' not the same as server version '${serverVersion}'. Please refresh`, new Class(4, []));
        }
        else {
            return defaultOf();
        }
    }
    else {
        return defaultOf();
    }
}

export function view(model, dispatch) {
    const activeWorkoutRecord = Session__GetActiveWorkoutRecord(model.Session);
    const children_9 = toList(delay(() => append(singleton_1(viewMainNavBar(model, dispatch)), delay(() => append(singleton_1(displayFavourites(model)), delay(() => append(singleton_1(displayTimer(model, dispatch)), delay(() => append(singleton_1(displayVersionWarning(model)), delay(() => {
        let children;
        return append(singleton_1((children = displayPermissionsWarnings(dispatch, model), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children)),
        }))), delay(() => {
            if (activeWorkoutRecord != null) {
                const patternInput = value_47(activeWorkoutRecord);
                return singleton_1(list_3(ofArray([new List_Option(8, [ofArray([new Modifier_IModifier(0, [new Color_IColor(8, [])]), new Modifier_IModifier(29, [new Spacing_TypeAndDirection(7, []), new Spacing_Amount(2, [])])])]), new List_Option(1, [])]), ofArray([button(ofArray([new Option(0, [new Color_IColor(0, [])]), new Option(4, []), new Option(16, [model.InProgress]), new Option(18, [(_arg) => {
                    Router_modifyLocation(new Route(38, [patternInput[1].WorkoutId]));
                }])]), toList(delay(() => {
                    const duration = op_Subtraction(common.getNow(), patternInput[0].EventTimestamp);
                    const durationStr = format("{0:D2}:{1:D2}:{2:D2}", hours(duration), minutes(duration), seconds(duration));
                    return singleton_1(createElement("span", {
                        children: reactApi.Children.toArray([durationStr]),
                    }));
                }))), button(ofArray([new Option(0, [new Color_IColor(7, [])]), new Option(16, [model.InProgress]), new Option(18, [(_arg_1) => {
                    if (window.confirm(`${"Confirm abort"}:
${"Are you sure you want to abort the workout?"}`)) {
                        (() => {
                            dispatch(new Msg(6, []));
                        })();
                    }
                }])]), singleton(createElement("span", {
                    children: reactApi.Children.toArray(["Abort"]),
                }))), button(ofArray([new Option(0, [new Color_IColor(4, [])]), new Option(16, [model.InProgress]), new Option(18, [(_arg_3) => {
                    if (window.confirm(`${"Confirm"}:
${"Are you sure you want to conclude the workout?"}`)) {
                        (() => {
                            dispatch(new Msg(5, []));
                        })();
                    }
                }])]), singleton(createElement("span", {
                    children: reactApi.Children.toArray(["Conclude"]),
                })))])));
            }
            else {
                return empty_1();
            }
        }));
    }))))))))));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_9)),
    });
}

