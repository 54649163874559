import { Common_parseOptions, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7 } from "../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library-js.4.19.3/MapUtil.js";

/**
 * Generate <footer class="footer"></footer>
 */
export function footer(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "footer"), (props, children_1) => react.createElement("footer", keyValueList(props, 1), ...children_1), children);
}

