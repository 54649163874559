import { Union } from "../fable-library-js.4.19.3/Types.js";
import { union_type, string_type } from "../fable-library-js.4.19.3/Reflection.js";

export class Error$ extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RequiredFieldIsEmpty", "ValidationFailed", "External"];
    }
}

export function Error$_$reflection() {
    return union_type("Fable.Form.Error.Error", [], Error$, () => [[], [["Item", string_type]], [["Item", string_type]]]);
}

