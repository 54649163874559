import { Fa_IconOption } from "./fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { EntryDescriptor, Actions, ModuleDescriptor, IconType } from "./Omnicv.Client.Common/ClientModule.js";
import { Route } from "./Omnicv.Client.Common/Router.js";
import { singleton } from "./fable_modules/fable-library-js.4.19.3/List.js";
import { FinancialModule_$reflection, FinancialModule } from "./Omnicv.Shared/FinancialAPI.js";
import { class_type } from "./fable_modules/fable-library-js.4.19.3/Reflection.js";

export class FinancialClientModule extends FinancialModule {
    constructor() {
        super();
    }
    Descriptor() {
        return new ModuleDescriptor(new IconType(new Fa_IconOption(11, ["fas fa-money-bill"])), (_arg) => (new Route(29, [])));
    }
    EntryDescriptors() {
        return singleton(new EntryDescriptor("AssetValueRecord", "Asset value", "Record the value of your assets", new IconType(new Fa_IconOption(11, ["fas fa-balance-scale-left"])), new Actions(undefined, undefined, undefined, undefined, undefined), false));
    }
}

export function FinancialClientModule_$reflection() {
    return class_type("Omnicv.Client.Financial.FinancialClientModule", undefined, FinancialClientModule, FinancialModule_$reflection());
}

export function FinancialClientModule_$ctor() {
    return new FinancialClientModule();
}

