import { Fa_IconOption } from "../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { EntryDescriptor, Actions, ModuleDescriptor, IconType } from "../../Omnicv.Client.Common/ClientModule.js";
import { Config_defaultDateForNow, Config_getDefaultDiaryDate } from "../../Omnicv.Client.Common/Extensions.js";
import { Route } from "../../Omnicv.Client.Common/Router.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { DiaryModule_$reflection, DiaryModule } from "../Omnicv.Diary.Shared/DiaryAPI.js";
import { class_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";

export class DiaryClientModule extends DiaryModule {
    constructor() {
        super();
    }
    Descriptor() {
        return new ModuleDescriptor(new IconType(new Fa_IconOption(11, ["fas fa-journal-whills"])), (session) => (new Route(9, [Config_getDefaultDiaryDate(session)])));
    }
    EntryDescriptors() {
        return ofArray([new EntryDescriptor("DiaryRecord", "Diary", "Diary entries", new IconType(new Fa_IconOption(11, ["fas fa-journal-whills"])), new Actions(new Route(10, [Config_defaultDateForNow]), (Item) => (new Route(10, [Item])), (Item_1) => (new Route(11, [Item_1])), undefined, undefined), true), new EntryDescriptor("ReviewRecord", "Review", "Review your experiences", new IconType(new Fa_IconOption(11, ["fas fa-star-half-alt"])), new Actions(new Route(12, [Config_defaultDateForNow]), (Item_2) => (new Route(12, [Item_2])), (Item_3) => (new Route(13, [Item_3])), undefined, undefined), false), new EntryDescriptor("InteractionRecord", "Interaction", "Track your interactions with others", new IconType(new Fa_IconOption(11, ["fas fa-phone-alt"])), new Actions(new Route(14, [Config_defaultDateForNow]), (Item_4) => (new Route(14, [Item_4])), (Item_5) => (new Route(15, [Item_5])), undefined, undefined), false)]);
    }
}

export function DiaryClientModule_$reflection() {
    return class_type("Omnicv.Client.Diary.DiaryClientModule", undefined, DiaryClientModule, DiaryModule_$reflection());
}

export function DiaryClientModule_$ctor() {
    return new DiaryClientModule();
}

