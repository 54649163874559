import { parseParams, parse } from "../Fable.Elmish.UrlParser.1.0.2/parser.fs.js";
import { substring } from "../fable-library-js.4.19.3/String.js";
import { empty } from "../fable-library-js.4.19.3/Map.js";
import { comparePrimitives } from "../fable-library-js.4.19.3/Util.js";

export function parsePath(parser, location) {
    return parse(parser, location.pathname, parseParams(location.search));
}

export function parseHash(parser, location) {
    let patternInput;
    const hash = (location.hash.length > 1) ? substring(location.hash, 1) : "";
    const pos = hash.indexOf("?") | 0;
    patternInput = ((pos >= 0) ? [substring(hash, 0, pos), parseParams(substring(hash, pos + 1))] : [hash, empty({
        Compare: comparePrimitives,
    })]);
    return parse(parser, patternInput[0], patternInput[1]);
}

