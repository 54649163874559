import { Record, Union } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { ReminderRecord_$reflection, InteractionRecord_$reflection, ReviewRecord_$reflection, DiaryRecord_$reflection } from "./Data.js";
import { unit_type, int64_type, lambda_type, record_type, list_type, tuple_type, class_type, int32_type, string_type, union_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { PagingOptions_$reflection, EventInfo_$reflection } from "../../Omnicv.Shared.Common/CoreEventInfo.js";
import { head, ofArray, singleton, empty, ofSeq } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { FSharpMap__get_Keys } from "../../fable_modules/fable-library-js.4.19.3/Map.js";
import { printf, toText } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { ChangelogEntry, Version, ProductInformation, Changelog, defaultWeb, defaultContacts, defaultAuthors } from "../../Omnicv.Shared.Common/ProductDetails.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.3/Date.js";

export class UnifiedRecord extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Diary", "Review", "Interaction"];
    }
}

export function UnifiedRecord_$reflection() {
    return union_type("Omnicv.Diary.Shared.DiaryAPI.UnifiedRecord", [], UnifiedRecord, () => [[["Item", DiaryRecord_$reflection()]], [["Item", ReviewRecord_$reflection()]], [["Item", InteractionRecord_$reflection()]]]);
}

export class EditHelperData$1 extends Record {
    constructor(FrequentTags, RecentRecords) {
        super();
        this.FrequentTags = FrequentTags;
        this.RecentRecords = RecentRecords;
    }
}

export function EditHelperData$1_$reflection(gen0) {
    return record_type("Omnicv.Diary.Shared.DiaryAPI.EditHelperData`1", [gen0], EditHelperData$1, () => [["FrequentTags", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, int32_type])], ["RecentRecords", list_type(tuple_type(EventInfo_$reflection(), gen0))]]);
}

export function EditHelperData$1__GetFrequentTags(this$) {
    return ofSeq(FSharpMap__get_Keys(this$.FrequentTags));
}

export class RecordService$1 extends Record {
    constructor(Add, Edit, GetRecord, GetRecords, SearchGeneral, SearchByTags, GetEditHelperData) {
        super();
        this.Add = Add;
        this.Edit = Edit;
        this.GetRecord = GetRecord;
        this.GetRecords = GetRecords;
        this.SearchGeneral = SearchGeneral;
        this.SearchByTags = SearchByTags;
        this.GetEditHelperData = GetEditHelperData;
    }
}

export function RecordService$1_$reflection(gen0) {
    return record_type("Omnicv.Diary.Shared.DiaryAPI.RecordService`1", [gen0], RecordService$1, () => [["Add", lambda_type(EventInfo_$reflection(), lambda_type(gen0, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [EventInfo_$reflection()])))], ["Edit", lambda_type(EventInfo_$reflection(), lambda_type(gen0, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [EventInfo_$reflection()])))], ["GetRecord", lambda_type(int32_type, lambda_type(int64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [tuple_type(EventInfo_$reflection(), gen0)])))], ["GetRecords", lambda_type(int32_type, lambda_type(class_type("System.DateTime"), lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(tuple_type(EventInfo_$reflection(), gen0))]))))], ["SearchGeneral", lambda_type(int32_type, lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(tuple_type(EventInfo_$reflection(), gen0))])))], ["SearchByTags", lambda_type(int32_type, lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(tuple_type(EventInfo_$reflection(), gen0))])))], ["GetEditHelperData", lambda_type(int32_type, lambda_type(PagingOptions_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [EditHelperData$1_$reflection(gen0)])))]]);
}

export class UnifiedService extends Record {
    constructor(GetRecord, GetRecords, SearchGeneral, SearchByTags, GetFrequentTags) {
        super();
        this.GetRecord = GetRecord;
        this.GetRecords = GetRecords;
        this.SearchGeneral = SearchGeneral;
        this.SearchByTags = SearchByTags;
        this.GetFrequentTags = GetFrequentTags;
    }
}

export function UnifiedService_$reflection() {
    return record_type("Omnicv.Diary.Shared.DiaryAPI.UnifiedService", [], UnifiedService, () => [["GetRecord", lambda_type(int32_type, lambda_type(int64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [tuple_type(EventInfo_$reflection(), UnifiedRecord_$reflection())])))], ["GetRecords", lambda_type(int32_type, lambda_type(class_type("System.DateTime"), lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(tuple_type(EventInfo_$reflection(), UnifiedRecord_$reflection()))]))))], ["SearchGeneral", lambda_type(int32_type, lambda_type(string_type, lambda_type(PagingOptions_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(tuple_type(EventInfo_$reflection(), UnifiedRecord_$reflection()))]))))], ["SearchByTags", lambda_type(int32_type, lambda_type(string_type, lambda_type(PagingOptions_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(tuple_type(EventInfo_$reflection(), UnifiedRecord_$reflection()))]))))], ["GetFrequentTags", lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, int32_type])]))]]);
}

export function UnifiedService_RouteBuilder(_arg, m) {
    return toText(printf("/api/auth/modules/dm/unified/%s"))(m);
}

export class ReminderService extends Record {
    constructor(GetReminders, MarkReminderDismissed) {
        super();
        this.GetReminders = GetReminders;
        this.MarkReminderDismissed = MarkReminderDismissed;
    }
}

export function ReminderService_$reflection() {
    return record_type("Omnicv.Diary.Shared.DiaryAPI.ReminderService", [], ReminderService, () => [["GetReminders", lambda_type(int32_type, lambda_type(class_type("System.DateTime"), lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(ReminderRecord_$reflection())]))))], ["MarkReminderDismissed", lambda_type(int32_type, lambda_type(int64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type])))]]);
}

export function ReminderService_RouteBuilder(_arg, m) {
    return toText(printf("/api/auth/modules/dm/remind/%s"))(m);
}

export class DiaryModule {
    constructor() {
        this.initialTimestamp = parse("2021-04-16T22:06:00");
        this.latestTimestamp = this.initialTimestamp;
    }
    get Details() {
        const this$ = this;
        return new ProductInformation("Omnicv.Diary", this$.initialTimestamp, this$.latestTimestamp, "Diary", "A diary module, where you can record your private thoughts, observations and ideas", empty(), DiaryModule__get_CurrentVersion(this$), singleton(defaultAuthors), defaultContacts, defaultWeb, undefined, undefined, new Changelog(1, [DiaryModule__get_Changelog(this$)]));
    }
    RegisteredTypes() {
        return ofArray([DiaryRecord_$reflection(), ReviewRecord_$reflection(), InteractionRecord_$reflection()]);
    }
}

export function DiaryModule_$reflection() {
    return class_type("Omnicv.Diary.Shared.DiaryAPI.DiaryModule", undefined, DiaryModule);
}

export function DiaryModule_$ctor() {
    return new DiaryModule();
}

export function DiaryModule__get_Changelog(this$) {
    return singleton(new ChangelogEntry(this$.initialTimestamp, new Version(0, 0, 1, undefined), "Initial release"));
}

export function DiaryModule__get_CurrentVersion(this$) {
    return head(DiaryModule__get_Changelog(this$)).Version;
}

