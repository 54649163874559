import { dateToStrForDiary, dateStrToDate, exnToDisplayString, countWords as countWords_1 } from "../../../Omnicv.Client.Common/Utils.js";
import { value as value_1, defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, unit_type, int64_type, record_type, int32_type, option_type, string_type, list_type, tuple_type, class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { EventInfo_$reflection } from "../../../Omnicv.Shared.Common/CoreEventInfo.js";
import { UnifiedRecord_$reflection } from "../../Omnicv.Diary.Shared/DiaryAPI.js";
import { ReminderRecord_$reflection } from "../../Omnicv.Diary.Shared/Data.js";
import { length, ofArray, singleton, empty, map, sum } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Route, Router_newUrl, Route_$reflection } from "../../../Omnicv.Client.Common/Router.js";
import * as common from "../../../../src/javascript/common.js";
import { addDays, op_Subtraction, date as date_3, toLongDateString, compare } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { Cmd_batch, Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Icons, Session__GetCurrentProfileId } from "../../../Omnicv.Client.Common/SharedView.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { curry3, curry2 } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { commonAuthService } from "../../../Omnicv.Client.Common/CommonServer.js";
import { unifiedService, reminderService } from "../Server.js";
import { Config_getUserTimeZone } from "../../../Omnicv.Client.Common/Extensions.js";
import { days } from "../../../fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { formatDistance } from "date-fns";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Class, closeable } from "../../../Omnicv.Client.Common/Components/Notification.js";
import { defaultOf } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { getModuleNewDateButtons } from "../../../Omnicv.Client.Common/ClientModule.js";
import { displayUnifiedEntries, displayReminders, diaryModule } from "../DiaryClientUtils.js";
import { Option, button } from "../../../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Screen, Size_ISize, Color_IColor } from "../../../fable_modules/Fulma.3.0.0/Common.fs.js";
import { Option as Option_3, icon } from "../../../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_i } from "../../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Icons__ToIcon } from "../../../Omnicv.Client.Common/SharedView.js";
import { Fa_i as Fa_i_1, Fa_IconOption } from "../../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { createElement } from "react";
import * as react from "react";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { Option as Option_1, div } from "../../../fable_modules/Fulma.3.0.0/Elements/Form/Control.fs.js";
import { input } from "../../../fable_modules/Fulma.3.0.0/Elements/Form/Input.fs.js";
import { Option as Option_2, IInputType } from "../../../fable_modules/Fulma.3.0.0/Elements/Form/Input.fs.js";
import { Option as Option_4, columns } from "../../../fable_modules/Fulma.3.0.0/Layouts/Columns.fs.js";
import { Option as Option_5, ISize, column } from "../../../fable_modules/Fulma.3.0.0/Layouts/Column.fs.js";
import { Option as Option_6, h3, h4 } from "../../../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";

function countWords(record) {
    switch (record.tag) {
        case 1: {
            const record_2 = record.fields[0];
            return countWords_1((record_2.Tagline + " ") + record_2.Notes) | 0;
        }
        case 2: {
            const record_3 = record.fields[0];
            return countWords_1((((((record_3.Tagline + " ") + record_3.Notes) + " ") + record_3.Participants) + " ") + defaultArg(record_3.FollowupNotes, "")) | 0;
        }
        default: {
            const record_1 = record.fields[0];
            return countWords_1((record_1.Tagline + " ") + record_1.Notes) | 0;
        }
    }
}

export class Model extends Record {
    constructor(Date$, Records, Reminders, Error$, PendingRequests) {
        super();
        this.Date = Date$;
        this.Records = Records;
        this.Reminders = Reminders;
        this.Error = Error$;
        this.PendingRequests = (PendingRequests | 0);
    }
}

export function Model_$reflection() {
    return record_type("Omnicv.Client.Pages.Diary.MainDiaryView.Component.Model", [], Model, () => [["Date", class_type("System.DateTime")], ["Records", list_type(tuple_type(EventInfo_$reflection(), UnifiedRecord_$reflection()))], ["Reminders", list_type(ReminderRecord_$reflection())], ["Error", option_type(string_type)], ["PendingRequests", int32_type]]);
}

export function Model__IsLoading(this$) {
    return this$.PendingRequests > 0;
}

export function Model__get_WordCount(this$) {
    return sum(map((tupledArg) => countWords(tupledArg[1]), this$.Records), {
        GetZero: () => 0,
        Add: (x, y) => (x + y),
    });
}

function addPendingRequest(model, requestsOpt) {
    return new Model(model.Date, model.Records, model.Reminders, model.Error, model.PendingRequests + ((requestsOpt == null) ? 1 : requestsOpt));
}

function pendingRequestDone(model, result) {
    return new Model(model.Date, model.Records, model.Reminders, (result.tag === 1) ? exnToDisplayString(result.fields[0]) : undefined, model.PendingRequests - 1);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RequestFetch", "FetchSucceeded", "RemindersFetchSucceeded", "FetchFailed", "Delete", "DeleteSucceeded", "MarkDismissed", "MarkDismissedSucceeded", "NavigateTo", "MoveToDate"];
    }
}

export function Msg_$reflection() {
    return union_type("Omnicv.Client.Pages.Diary.MainDiaryView.Component.Msg", [], Msg, () => [[], [["Item", list_type(tuple_type(EventInfo_$reflection(), UnifiedRecord_$reflection()))]], [["Item", list_type(ReminderRecord_$reflection())]], [["Item", class_type("System.Exception")]], [["Item", int64_type]], [["Item", string_type]], [["Item", int64_type]], [["Item", unit_type]], [["Item", Route_$reflection()]], [["Item", string_type]]]);
}

export function init(date) {
    let converted;
    const now = common.getNow();
    return [new Model((date == null) ? now : ((converted = dateStrToDate(now, date), (compare(converted, now) > 0) ? now : converted)), empty(), empty(), undefined, 0), singleton((dispatch) => {
        dispatch(new Msg(0, []));
    })];
}

export function update(session, msg, model) {
    switch (msg.tag) {
        case 1:
            return [pendingRequestDone(new Model(model.Date, msg.fields[0], model.Reminders, model.Error, model.PendingRequests), new FSharpResult$2(0, [undefined])), Cmd_none()];
        case 2:
            return [pendingRequestDone(new Model(model.Date, model.Records, msg.fields[0], model.Error, model.PendingRequests), new FSharpResult$2(0, [undefined])), Cmd_none()];
        case 4: {
            const profileId_1 = Session__GetCurrentProfileId(session) | 0;
            return [addPendingRequest(model, undefined), Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, curry2(commonAuthService.DeleteRecord)(profileId_1), msg.fields[0], (Item_4) => (new Msg(5, [Item_4])), (Item_5) => (new Msg(3, [Item_5])))];
        }
        case 6: {
            const profileId_2 = Session__GetCurrentProfileId(session) | 0;
            return [addPendingRequest(model, undefined), Cmd_OfAsyncWith_either((x_3) => {
                Cmd_OfAsync_start(x_3);
            }, curry2(reminderService.MarkReminderDismissed)(profileId_2), msg.fields[0], () => (new Msg(7, [undefined])), (Item_7) => (new Msg(3, [Item_7])))];
        }
        case 5:
        case 7:
            return [pendingRequestDone(model, new FSharpResult$2(0, [undefined])), singleton((dispatch) => {
                dispatch(new Msg(0, []));
            })];
        case 3:
            return [pendingRequestDone(model, new FSharpResult$2(1, [msg.fields[0]])), Cmd_none()];
        case 8:
            return [model, Router_newUrl(msg.fields[0])];
        case 9:
            return [model, Router_newUrl(new Route(9, [msg.fields[0]]))];
        default: {
            const profileId = Session__GetCurrentProfileId(session) | 0;
            const timezone = Config_getUserTimeZone(session);
            return [addPendingRequest(model, 2), Cmd_batch(ofArray([Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, curry3(unifiedService.GetRecords)(profileId)(model.Date), timezone, (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(3, [Item_1]))), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, curry3(reminderService.GetReminders)(profileId)(model.Date), timezone, (Item_2) => (new Msg(2, [Item_2])), (Item_3) => (new Msg(3, [Item_3])))]))];
        }
    }
}

export function view(session, model, dispatch) {
    const now = common.getNow();
    const dateStr = toLongDateString(date_3(model.Date));
    const diff = days(op_Subtraction(date_3(now), date_3(model.Date))) | 0;
    const isLoading = Model__IsLoading(model);
    let countStr;
    if (isLoading) {
        countStr = "";
    }
    else {
        const wordCount = Model__get_WordCount(model) | 0;
        countStr = (` | ${length(model.Records)} entry(ies) | ${wordCount} words`);
    }
    const patternInput = (diff === 0) ? ["Today", dateStr, false] : ((diff === 1) ? ["Yesterday", dateStr, true] : ((diff < 0) ? [dateStr, "Future", false] : [dateStr, formatDistance(date_3(now), model.Date, {}), true]));
    const hasNext = patternInput[2];
    const prevDate = dateToStrForDiary(addDays(model.Date, -1));
    const nextDate = dateToStrForDiary(addDays(model.Date, 1));
    const children_10 = toList(delay(() => {
        let matchValue;
        return append((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Can't fetch diary data ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => {
            let children_4;
            const date_1 = dateToStrForDiary(model.Date);
            return append(singleton_1((children_4 = toList(delay(() => append(singleton_1(getModuleNewDateButtons(diaryModule, isLoading ? true : value_1(session.UserInfo).ReadOnly, date_1)), delay(() => append(singleton_1(button(ofArray([new Option(0, [new Color_IColor(7, [])]), new Option(16, [isLoading]), new Option(18, [(_arg) => {
                dispatch(new Msg(8, [new Route(17, [])]));
            }])]), singleton(icon(empty(), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(7, []))), [])))))), delay(() => {
                let children;
                return append(singleton_1(button(ofArray([new Option(0, [new Color_IColor(5, [])]), new Option(16, [isLoading]), new Option(18, [(_arg_1) => {
                    dispatch(new Msg(0, []));
                }])]), ofArray([icon(empty(), toList(delay(() => (isLoading ? singleton_1(Fa_i(ofArray([Icons__ToIcon(new Icons(6, [])), new Fa_IconOption(12, [])]), [])) : singleton_1(Fa_i(singleton(Icons__ToIcon(new Icons(5, []))), [])))))), (children = toList(delay(() => (isLoading ? singleton_1("Loading...") : singleton_1("Reload")))), createElement("span", {
                    children: reactApi.Children.toArray(Array.from(children)),
                }))]))), delay(() => append(hasNext ? singleton_1(button(ofArray([new Option(0, [new Color_IColor(6, [])]), new Option(16, [isLoading]), new Option(18, [(_arg_2) => {
                    dispatch(new Msg(9, ["today"]));
                }])]), ofArray([icon(empty(), singleton(Fa_i_1(singleton(new Fa_IconOption(11, ["fas fa-calendar-check"])), []))), createElement("span", {
                    children: reactApi.Children.toArray(["Today"]),
                })]))) : empty_1(), delay(() => singleton_1(div(ofArray([new Option_1(1, []), new Option_1(4, ["archive-calendar"])]), ofArray([input(ofArray([new Option_2(1, [new IInputType(3, [])]), new Option_2(12, ["Go to date"]), new Option_2(8, [dateToStrForDiary(model.Date)]), new Option_2(4, [isLoading]), new Option_2(13, [(event) => {
                    const elem = event.target;
                    const date_2 = elem.valueAsDate;
                    if (!(date_2 == null) && (compare(date_3(now), date_3(date_2)) >= 0)) {
                        dispatch(new Msg(9, [elem.value]));
                    }
                }])])), icon(ofArray([new Option_3(0, [new Size_ISize(0, [])]), new Option_3(1, [])]), singleton(Fa_i_1(singleton(new Fa_IconOption(11, ["fas fa-archive"])), [])))])))))));
            })))))), react.createElement("div", {
                className: "block",
                disabled: isLoading,
            }, ...children_4))), delay(() => append(singleton_1(columns(ofArray([new Option_4(4, []), new Option_4(1, [])]), ofArray([column(empty(), singleton(button(ofArray([new Option(0, [new Color_IColor(6, [])]), new Option(16, [isLoading]), new Option(18, [(_arg_3) => {
                dispatch(new Msg(9, [prevDate]));
            }])]), singleton(icon(empty(), singleton(Fa_i_1(singleton(new Fa_IconOption(11, ["fas fa-angle-double-left"])), []))))))), column(singleton(new Option_5(0, [new Screen(0, []), new ISize(21, [])])), singleton(h4(empty())(singleton(patternInput[0])))), column(empty(), singleton(button(ofArray([new Option(0, [new Color_IColor(6, [])]), new Option(16, [isLoading ? true : !hasNext]), new Option(18, [(_arg_4) => {
                dispatch(new Msg(9, [nextDate]));
            }])]), singleton(icon(empty(), singleton(Fa_i_1(singleton(new Fa_IconOption(11, ["fas fa-angle-double-right"])), [])))))))]))), delay(() => append(singleton_1(h3(singleton(new Option_6(6, [])))(singleton(patternInput[1] + countStr))), delay(() => {
                let children_6;
                return append(singleton_1((children_6 = displayReminders(model.Reminders, isLoading, (arg_1) => {
                    dispatch(new Msg(6, [arg_1]));
                }), createElement("div", {
                    children: reactApi.Children.toArray(Array.from(children_6)),
                }))), delay(() => {
                    let children_8;
                    return singleton_1((children_8 = displayUnifiedEntries(model.Records, isLoading, (arg) => {
                        dispatch(new Msg(4, [arg]));
                    }), createElement("div", {
                        children: reactApi.Children.toArray(Array.from(children_8)),
                    })));
                }));
            }))))));
        }));
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_10)),
    });
}

