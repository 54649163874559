import { Record } from "../fable-library-js.4.19.3/Types.js";
import { record_type, lambda_type } from "../fable-library-js.4.19.3/Reflection.js";

export class Field$3 extends Record {
    constructor(Value, Update, Attributes) {
        super();
        this.Value = Value;
        this.Update = Update;
        this.Attributes = Attributes;
    }
}

export function Field$3_$reflection(gen0, gen1, gen2) {
    return record_type("Fable.Form.Field.Field`3", [gen0, gen1, gen2], Field$3, () => [["Value", gen1], ["Update", lambda_type(gen1, gen2)], ["Attributes", gen0]]);
}

export function mapValues(fn, field) {
    return new Field$3(field.Value, (arg) => fn(field.Update(arg)), field.Attributes);
}

