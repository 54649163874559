import { Union, Record } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { int64_type, lambda_type, unit_type, uint32_type, tuple_type, enum_type, class_type, option_type, int32_type, list_type, union_type, record_type, bool_type, string_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { printf, toText, split, isNullOrWhiteSpace } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { Constraints_collectConstraintsErrors, Constraints_applyConstraintsGetErrors, Constraints_regexConstraint, Constraints_minMaxLengthConstraint } from "../Omnicv.Shared.Common/Validation.js";
import { singleton, ofArray } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { tryFind } from "../fable_modules/fable-library-js.4.19.3/Map.js";
import { utcNow, compare } from "../fable_modules/fable-library-js.4.19.3/Date.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.19.3/Result.js";
import { EventInfo_$reflection } from "../Omnicv.Shared.Common/CoreEventInfo.js";
import { WorkoutRecord_$reflection } from "./FitnessAPI.js";
import { item } from "../fable_modules/fable-library-js.4.19.3/Array.js";

export class Request_Login extends Record {
    constructor(Username, Password, IsPersistent) {
        super();
        this.Username = Username;
        this.Password = Password;
        this.IsPersistent = IsPersistent;
    }
}

export function Request_Login_$reflection() {
    return record_type("Omnicv.Shared.API.Request.Login", [], Request_Login, () => [["Username", string_type], ["Password", string_type], ["IsPersistent", bool_type]]);
}

export function Request_Login__IsComplete(this$) {
    return !(isNullOrWhiteSpace(this$.Username) ? true : isNullOrWhiteSpace(this$.Password));
}

export class Request_ExternalAuthScheme extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Google"];
    }
}

export function Request_ExternalAuthScheme_$reflection() {
    return union_type("Omnicv.Shared.API.Request.ExternalAuthScheme", [], Request_ExternalAuthScheme, () => [[]]);
}

export class Response_JwtToken extends Record {
    constructor(Token) {
        super();
        this.Token = Token;
    }
}

export function Response_JwtToken_$reflection() {
    return record_type("Omnicv.Shared.API.Response.JwtToken", [], Response_JwtToken, () => [["Token", string_type]]);
}

export class Response_SiteSettings extends Record {
    constructor(FitnessContentTypes) {
        super();
        this.FitnessContentTypes = FitnessContentTypes;
    }
}

export function Response_SiteSettings_$reflection() {
    return record_type("Omnicv.Shared.API.Response.SiteSettings", [], Response_SiteSettings, () => [["FitnessContentTypes", list_type(Response_ContentTypeDescriptor_$reflection())]]);
}

export class Response_ContentTypeDescriptor extends Record {
    constructor(Id, Name, Description) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Description = Description;
    }
}

export function Response_ContentTypeDescriptor_$reflection() {
    return record_type("Omnicv.Shared.API.Response.ContentTypeDescriptor", [], Response_ContentTypeDescriptor, () => [["Id", int32_type], ["Name", string_type], ["Description", option_type(string_type)]]);
}

const Response_fullNameConstraints = ofArray([(name) => ((value) => Constraints_minMaxLengthConstraint(2, 256, name, value)), (name_1) => ((value_1) => Constraints_regexConstraint(/^\p{L}[\p{L} ,.'-]*\p{L}$/gu, name_1, value_1))]);

export const Response_fullNameName = "FullName";

function Response_getFullNameErrors(fullName) {
    return Constraints_applyConstraintsGetErrors(Response_fullNameConstraints, Response_fullNameName, fullName);
}

export function Response_validateFullName(fullName) {
    return tryFind(Response_fullNameName, Constraints_collectConstraintsErrors(singleton(Response_getFullNameErrors(fullName))));
}

function Response_notInTheFuture(name, date) {
    if (compare(date, utcNow()) >= 0) {
        return new FSharpResult$2(1, [[name, `Date ${date} is in the future`]]);
    }
    else {
        return new FSharpResult$2(0, [date]);
    }
}

function Response_dateOfBirthConstraints() {
    return singleton((name) => ((date) => Response_notInTheFuture(name, date)));
}

export const Response_dateOfBirthName = "DateOfBirth";

function Response_getDateOfBirthErrors(dateOfBirth) {
    return Constraints_applyConstraintsGetErrors(Response_dateOfBirthConstraints(), Response_dateOfBirthName, dateOfBirth);
}

export function Response_validateDateOfBirth(dateOfBirth) {
    return tryFind(Response_dateOfBirthName, Constraints_collectConstraintsErrors(singleton(Response_getDateOfBirthErrors(dateOfBirth))));
}

export class Response_ProfileInfo extends Record {
    constructor(Id, IsPrimary, FullName, DateOfBirth, Gender, Country_IsoCode, ActiveWorkout) {
        super();
        this.Id = (Id | 0);
        this.IsPrimary = IsPrimary;
        this.FullName = FullName;
        this.DateOfBirth = DateOfBirth;
        this.Gender = (Gender | 0);
        this.Country_IsoCode = Country_IsoCode;
        this.ActiveWorkout = ActiveWorkout;
    }
}

export function Response_ProfileInfo_$reflection() {
    return record_type("Omnicv.Shared.API.Response.ProfileInfo", [], Response_ProfileInfo, () => [["Id", int32_type], ["IsPrimary", bool_type], ["FullName", string_type], ["DateOfBirth", class_type("System.DateTime")], ["Gender", enum_type("Omnicv.Common.Identity.Gender", int32_type, [["Male", 0], ["Female", 1], ["Other", 2]])], ["Country_IsoCode", string_type], ["ActiveWorkout", option_type(tuple_type(EventInfo_$reflection(), WorkoutRecord_$reflection()))]]);
}

export function Response_ProfileInfo_Default() {
    return new Response_ProfileInfo(0, false, "", utcNow(), 0, "", undefined);
}

function Response_ProfileInfo_Validator_Z1C27483E(record) {
    return Constraints_collectConstraintsErrors(ofArray([Response_getFullNameErrors(record.FullName), Response_getDateOfBirthErrors(record.DateOfBirth)]));
}

export function Response_ProfileInfo__Validate(this$) {
    return Response_ProfileInfo_Validator_Z1C27483E(this$);
}

export function Response_ProfileInfo__DisplayName(this$) {
    return item(0, split(this$.FullName, [" "], undefined, 0));
}

export class Response_UserInfo extends Record {
    constructor(Id, Name, Email, ReadOnly, Profiles, SiteSettings, ServerVersion, ClientConfigVariables) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Email = Email;
        this.ReadOnly = ReadOnly;
        this.Profiles = Profiles;
        this.SiteSettings = SiteSettings;
        this.ServerVersion = ServerVersion;
        this.ClientConfigVariables = ClientConfigVariables;
    }
}

export function Response_UserInfo_$reflection() {
    return record_type("Omnicv.Shared.API.Response.UserInfo", [], Response_UserInfo, () => [["Id", int32_type], ["Name", string_type], ["Email", string_type], ["ReadOnly", bool_type], ["Profiles", list_type(Response_ProfileInfo_$reflection())], ["SiteSettings", Response_SiteSettings_$reflection()], ["ServerVersion", string_type], ["ClientConfigVariables", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])]]);
}

export class Response_AdminUserList extends Record {
    constructor(Users, Page) {
        super();
        this.Users = Users;
        this.Page = Page;
    }
}

export function Response_AdminUserList_$reflection() {
    return record_type("Omnicv.Shared.API.Response.AdminUserList", [], Response_AdminUserList, () => [["Users", list_type(Response_UserInfo_$reflection())], ["Page", uint32_type]]);
}

export class Response_AnonymousData extends Record {
    constructor(ClientConfigVariables) {
        super();
        this.ClientConfigVariables = ClientConfigVariables;
    }
}

export function Response_AnonymousData_$reflection() {
    return record_type("Omnicv.Shared.API.Response.AnonymousData", [], Response_AnonymousData, () => [["ClientConfigVariables", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])]]);
}

export class Response_AvailableUserInfo extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Anonumous", "Authenticated"];
    }
}

export function Response_AvailableUserInfo_$reflection() {
    return union_type("Omnicv.Shared.API.Response.AvailableUserInfo", [], Response_AvailableUserInfo, () => [[["Item", Response_AnonymousData_$reflection()]], [["Item", Response_UserInfo_$reflection()]]]);
}

export class PageFormat extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Markdown"];
    }
}

export function PageFormat_$reflection() {
    return union_type("Omnicv.Shared.API.PageFormat", [], PageFormat, () => [[]]);
}

export class StaticPage extends Record {
    constructor(Format, Title, Body, Language) {
        super();
        this.Format = Format;
        this.Title = Title;
        this.Body = Body;
        this.Language = Language;
    }
}

export function StaticPage_$reflection() {
    return record_type("Omnicv.Shared.API.StaticPage", [], StaticPage, () => [["Format", PageFormat_$reflection()], ["Title", string_type], ["Body", string_type], ["Language", string_type]]);
}

export class ContactData extends Record {
    constructor(Name, Email, Subject, Url, Message) {
        super();
        this.Name = Name;
        this.Email = Email;
        this.Subject = Subject;
        this.Url = Url;
        this.Message = Message;
    }
}

export function ContactData_$reflection() {
    return record_type("Omnicv.Shared.API.ContactData", [], ContactData, () => [["Name", string_type], ["Email", string_type], ["Subject", string_type], ["Url", option_type(string_type)], ["Message", string_type]]);
}

export class AnonymousService extends Record {
    constructor(Contact, GetStaticPage, About, Login, GetAvailableUserInfo) {
        super();
        this.Contact = Contact;
        this.GetStaticPage = GetStaticPage;
        this.About = About;
        this.Login = Login;
        this.GetAvailableUserInfo = GetAvailableUserInfo;
    }
}

export function AnonymousService_$reflection() {
    return record_type("Omnicv.Shared.API.AnonymousService", [], AnonymousService, () => [["Contact", lambda_type(ContactData_$reflection(), lambda_type(option_type(string_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type])))], ["GetStaticPage", lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [StaticPage_$reflection()]))], ["About", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [string_type]))], ["Login", lambda_type(Request_Login_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [Response_UserInfo_$reflection()]))], ["GetAvailableUserInfo", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [Response_AvailableUserInfo_$reflection()]))]]);
}

export function AnonymousService_RouteBuilder(_arg, m) {
    return toText(printf("/api/public/%s"))(m);
}

export class SummaryEntry extends Record {
    constructor(Caption, ContentType, ContentTypeId, Count) {
        super();
        this.Caption = Caption;
        this.ContentType = ContentType;
        this.ContentTypeId = (ContentTypeId | 0);
        this.Count = (Count | 0);
    }
}

export function SummaryEntry_$reflection() {
    return record_type("Omnicv.Shared.API.SummaryEntry", [], SummaryEntry, () => [["Caption", string_type], ["ContentType", string_type], ["ContentTypeId", int32_type], ["Count", int32_type]]);
}

export class CommonAuthService extends Record {
    constructor(Logout, GetUserInfo, UpdateEventTimestamp, DeleteRecord, GetProfile, AddProfile, EditProfile, DeleteProfile, GetContentSummary) {
        super();
        this.Logout = Logout;
        this.GetUserInfo = GetUserInfo;
        this.UpdateEventTimestamp = UpdateEventTimestamp;
        this.DeleteRecord = DeleteRecord;
        this.GetProfile = GetProfile;
        this.AddProfile = AddProfile;
        this.EditProfile = EditProfile;
        this.DeleteProfile = DeleteProfile;
        this.GetContentSummary = GetContentSummary;
    }
}

export function CommonAuthService_$reflection() {
    return record_type("Omnicv.Shared.API.CommonAuthService", [], CommonAuthService, () => [["Logout", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [string_type]))], ["GetUserInfo", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [Response_UserInfo_$reflection()]))], ["UpdateEventTimestamp", lambda_type(int32_type, lambda_type(int64_type, lambda_type(class_type("System.DateTime"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [string_type]))))], ["DeleteRecord", lambda_type(int32_type, lambda_type(int64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [string_type])))], ["GetProfile", lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [Response_ProfileInfo_$reflection()]))], ["AddProfile", lambda_type(Response_ProfileInfo_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [int32_type]))], ["EditProfile", lambda_type(Response_ProfileInfo_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type]))], ["DeleteProfile", lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type]))], ["GetContentSummary", lambda_type(int32_type, lambda_type(enum_type("Omnicv.Shared.API.SummaryGroupByDate", int32_type, [["None", 0], ["Year", 1], ["Quarter", 2], ["Month", 3]]), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(SummaryEntry_$reflection())])))]]);
}

export function CommonAuthService_RouteBuilder(_arg, m) {
    return toText(printf("/api/auth/common/%s"))(m);
}

export class AdminAuthService extends Record {
    constructor(GetUsers) {
        super();
        this.GetUsers = GetUsers;
    }
}

export function AdminAuthService_$reflection() {
    return record_type("Omnicv.Shared.API.AdminAuthService", [], AdminAuthService, () => [["GetUsers", lambda_type(option_type(uint32_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [Response_AdminUserList_$reflection()]))]]);
}

export function AdminAuthService_RouteBuilder(_arg, m) {
    return toText(printf("/api/auth/admin/%s"))(m);
}

